import React from "react"
import axios from "axios";
import environment from "../environment";

class ApiLogErrorBoundary extends React.Component {
	static displayName = 'ApiLogErrorBoundary';
state = {
        error: null,

    }
    language = navigator.language || navigator.userLanguage;

    static getDerivedStateFromError(error) {
        // Update state so next render shows fallback UI.
        return { error: error };
    }

    componentDidCatch(error, info) {
        // Log the error to an error reporting service


        const body = {
            message: error.message,
            context: info,
            error: JSON.stringify(error),
        }
        const xdebug = environment.environment !== 'production' ? '?XDEBUG_SESSION_START=PHPSTORM' : '';
        const request = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            data: JSON.stringify(body)
        };

        try{
            console.error('Error boundary:', error, info);
            axios(environment.tx_api_base_path + '/api/v1/error-boundary' + xdebug, request);
        } catch (e) {
            console.error(e);
        }

    }

    render() {
        if (this.state.error) {
            // You can render any custom fallback UI
            switch(this.language.substr(0,2)) {
                case 'fr':
                    return <div className={'error-boundary-container text-center'}>
                        <p>Désolé, une erreur est survenue.</p>
                        <p><a href="https://toxseek.com">retourner sur toxseek.com</a></p>
                    </div>;
                case 'de':
                    return <div className={'error-boundary-container text-center'}>
                        <p>Entschuldigung, es ist ein Fehler aufgetreten</p>
                        <p><a href="https://toxseek.com">zurück zu toxseek.com</a></p>
                    </div>;
                default:
                    return <div className={'error-boundary-container text-center'}>
                        <p>Sorry, an error occured.</p>
                        <p><a href="https://toxseek.com">back to toxseek.com</a></p>
                    </div>;

            }

        }
        return this.props.children;
    }
}

export default ApiLogErrorBoundary;
