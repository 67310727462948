import React from "react";
import {departments} from "./department";
import {PrerenderedComponent} from "react-prerendered-component";

const prefetchMap = new WeakMap();
const prefetchLazy = LazyComponent => {
    if (!prefetchMap.has(LazyComponent)) {
        prefetchMap.set(LazyComponent, LazyComponent._ctor());
    }
    return prefetchMap.get(LazyComponent);
};

const prerenderedLazy = dynamicImport => {
    const LazyComponent = React.lazy(dynamicImport);
    return React.memo(props => (
        <PrerenderedComponent live={prefetchLazy(LazyComponent)}>
            <LazyComponent {...props} />
        </PrerenderedComponent>
    ));
};


const HomePage = prerenderedLazy(() => import("./containers/HomePage"));
const RedirectPage = prerenderedLazy(() => import("./containers/RedirectPage"));
const AccountPage = prerenderedLazy(() => import("./containers/AccountPage"));
const OrderPage = prerenderedLazy(() => import("./containers/OrderPage"));
const PaymentResultPage = prerenderedLazy(() => import("./containers/PaymentResultPage"));
const ActivationPage = prerenderedLazy(() => import("./containers/ActivationPage"));
const AnalysesPage = prerenderedLazy(() => import("./containers/AnalysesPage"));
const WhyPage = prerenderedLazy(() => import("./containers/WhyPage"));
const PressPage = prerenderedLazy(() => import("./containers/PressPage"));
const ArticleMarieclaire = prerenderedLazy(() => import("./containers/ArticleMarieclaire"));
const ArticleReporterre = prerenderedLazy(() => import("./containers/ArticleReporterre"));
const ArticleObs = prerenderedLazy(() => import("./containers/ArticleObs"));
const ArticleGenFutures = prerenderedLazy(() => import("./containers/ArticleGenFutures"));
const ArticleKtipp = prerenderedLazy(() => import("./containers/ArticleKtipp"));
const ArticleBonasavoir = prerenderedLazy(() => import("./containers/ArticleBonasavoir"));
const ArticleBonasavoir2 = prerenderedLazy(() => import("./containers/ArticleBonasavoir2"));
const ArticleFranceBleu = prerenderedLazy(() => import("./containers/ArticleFranceBleu"));
const ArticleRue89 = prerenderedLazy(() => import("./containers/ArticleRue89.js"));
const AboutPage = prerenderedLazy(() => import("./containers/AboutPage"));
const ContactPage = prerenderedLazy(() => import("./containers/ContactPage.js"));
const CheckoutPage = prerenderedLazy(() => import("./containers/CheckoutPage"));
const LegalNoticePage = prerenderedLazy(() => import("./containers/LegalNoticePage"));
const CgvPage = prerenderedLazy(() => import("./containers/CgvPage"));
const PrivacyPolicyPage = prerenderedLazy(() => import("./containers/PrivacyPolicyPage"));
const CountrySelectorPage = prerenderedLazy(() => import("./containers/CountriesPage"));
const LogoutPage = prerenderedLazy(() => import("./containers/LogoutPage"));
const VeterinaryHome = prerenderedLazy(() => import("./containers/Veterinary/VeterinaryHome"));
const VeterinaryWhat = prerenderedLazy(() => import("./containers/Veterinary/VeterinaryWhat"));
const VeterinaryStudyCase = prerenderedLazy(() => import("./containers/Veterinary/VeterinaryStudyCase"));

const pages = {
    redirect:                       {path: '/redirect',                     translated: false, unlocalizedPath: '/redirect', component: RedirectPage},
    slink:                          {path: '/slink',                        translated: false, component: (props) => (<HomePage {...props} slink={true}/>)},
    home:                           {path: '/',                             translated: false, component: HomePage},
    logout:                         {path: 'seo.url.account-logout',        component: LogoutPage},
    accountInfo:                    {path: 'seo.url.account-info',          component: (props) => <AccountPage {...props} selectedTab={'profile'}/>, private: true},
    accountOrders:                  {path: 'seo.url.account-orders',        component: (props) => <AccountPage {...props} selectedTab={'orders'}/>, private: true},
    accountResults:                 {path: 'seo.url.account-results',       component: (props) => <AccountPage {...props} selectedTab={'results'}/>, private: true},
    accountAnalyses:                {path: 'seo.url.account-analyses',      component: (props) => <AccountPage {...props} selectedTab={'results'}/>, private: true},
    order:                          {path: 'seo.url.order',                 unlocalizedPath: '/order-preparation',        component: OrderPage},
    paymentResult:                  {path: 'seo.url.payment-result',                component: PaymentResultPage},
    activation:                     {path: 'seo.url.activation',            unlocalizedPath: '/activation',               component: ActivationPage},
    press:                          {path: 'seo.url.press',                         component: PressPage},
    articleMarieClaire:             {path: 'seo.url.press-article-marieclaire',     component: ArticleMarieclaire},
    articleReporterre:              {path: 'seo.url.press-article-reporterre',      component: ArticleReporterre},
    articleObs:                     {path: 'seo.url.press-article-l-obs',           component: ArticleObs},
    articleGenFutures:              {path: 'seo.url.press-article-gen-futures',     component: ArticleGenFutures},
    articleKtipp:                   {path: 'seo.url.press-article-ktipp',     component: ArticleKtipp},
    articleBonasavoir:              {path: 'seo.url.press-article-bonasavoir',     component: ArticleBonasavoir},
    articleBonasavoir2:             {path: 'seo.url.press-article-bonasavoir2',     component: ArticleBonasavoir2},
    articleFranceBleu:              {path: 'seo.url.press-article-francebleu',     component: ArticleFranceBleu},
    articleRue89:                   {path: 'seo.url.press-article-rue89',     component: ArticleRue89},
    about:                          {path: 'seo.url.about',                 component: AboutPage},
    checkout:                       {path: 'seo.url.checkout',              component: CheckoutPage},
    legalNotice:                    {path: 'seo.url.legal-notice',          component: LegalNoticePage},
    privacyPolicy:                  {path: 'seo.url.privacy-policy',        component: PrivacyPolicyPage},
    cgv:                            {path: 'seo.url.cgv',                   component: CgvPage},
    countries:                      {path: 'seo.url.countries',             component: CountrySelectorPage},
    why:                            {path: 'seo.url.why',                   component: WhyPage},
    analyseMetal:                   {path: 'seo.url.analyse.metal',         component: (props) => <AnalysesPage {...props} selectedTab={'metal'}/>},
    analyseOrganic:                 {path: 'seo.url.analyse.organic',       component: (props) => <AnalysesPage {...props} selectedTab={'organic'}/>},
    analyseIntegral:                {path: 'seo.url.analyse.integral',      component: (props) => <AnalysesPage {...props} selectedTab={'integral'}/>},
    contactPro:                     {path: 'seo.url.contact.pro',           component: (props) => <ContactPage {...props} selectedTab={'pro'}/>},
    contactAsso:                    {path: 'seo.url.contact.asso',          component: (props) => <ContactPage {...props} selectedTab={'asso'}/>},
    contactPress:                   {path: 'seo.url.contact.press',         component: (props) => <ContactPage {...props} selectedTab={'press'}/>},
    contactIndividual:              {path: 'seo.url.contact.individual',    component: (props) => <ContactPage {...props} selectedTab={'individual'}/>},

    //Veterinary
    veterinaryHome:                 {path: 'seo.url.veterinary.home',       component: VeterinaryHome,      department: departments.veterinary},
    veterinaryWhat:                 {path: 'seo.url.veterinary.what',       component: VeterinaryWhat,      department: departments.veterinary},
    veterinaryStudyCase:            {path: 'seo.url.veterinary.study-case', component: VeterinaryStudyCase, department: departments.veterinary},
    veterinaryCountries:            {path: 'seo.url.veterinary.countries',  component: CountrySelectorPage, department: departments.veterinary},
    //debug
    //translation:        {path: '/translation',                   translated: false, component: TranslationPage},
};

export default pages;
