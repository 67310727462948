import React from "react";
import {withRouter} from "react-router-dom";
import Button from "react-bootstrap/Button";
import {Helmet} from "react-helmet";
import Modal from "react-bootstrap/Modal";
import Image from "react-bootstrap/Image";
import TextLoop from "react-text-loop"
import {withLocalization} from "../../Providers/LocalizationProvider";

class LocaleSelectorPage extends React.Component {
	static displayName = 'LocaleSelectorPage';
handleSelectLocale(locale) {
        this.props.history.push('/' + this.props.localization.country.code + locale)
    }

    render() {
        let messages = [];
        const country = this.props.localization.country;

        const buttons = Object.keys(country.locale).map((locale) => {
            messages.push(country.locale[locale].message);
            return (
                <Button onClick={() => this.handleSelectLocale(locale)} className={'m-3'}>
                    {country.locale[locale].label}
                </Button>
            )
        });

        const messagesComponent = messages.map((message) => {
            return (
                <div style={{width: '400px', textAlign: 'center'}}>{message}</div>
            )
        });

        return (
            <div className="App">
                <Helmet titleTemplate="%s - toxSeek" defaultTitle="Home">
                    <meta name="description" content="toxSeek website"/>
                </Helmet>
                <div className="container ">
                    <Modal
                        className={'toxseek-background'}
                        show={true}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Body>
                            <div className={'d-flex flex-column align-items-center'}>
                                <Image src={'/images/identity/Logo-320x132-world.png'} className={'mb-2'}/>
                                <TextLoop >
                                    {messagesComponent}
                                </TextLoop>
                                <div className={'flex-row '}>
                                    {buttons}
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        )
    }
}



export default withLocalization(withRouter(LocaleSelectorPage));
