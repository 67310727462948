import React from 'react';
import {FiMail} from "react-icons/fi";
import {FaLinkedinIn} from "react-icons/fa";
import IntlText from "./Intl/Text";
import AppLink from "./AppLink";
import {ucFirst} from "../utils/functions";
import {withLocalization} from "../Providers/LocalizationProvider";
import IntlImg from "./Intl/IntlImg";
import {withDepartment} from "../Providers/DepartmentProvider";


function Footer(props) {

return (
        <footer id="site-footer">
            <div id="site-footer-top">
                <div className="container">
                    <div id="site-footer-logo">
                        <div className="row align-items-end">
                            <div className="col-6">
                                <span className="logo"><IntlImg src={props.department.logo} width="169" alt="logo toxseek"/></span>
                            </div>
                            <div className="col-6">
                                <div className="social">
                                    <ul>
                                        <li>
                                            <FaLinkedinIn/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div id="site-footer-menus">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <ul>
                                            {/*<li><AppLink page={'analyseIntegral'}><IntlText messageId={'footer.analyses'}/></AppLink></li>*/}
                                            {
                                                ['integral', 'metal', 'organic'].map((name, index) => (
                                                    <li key={index}><AppLink page={'analyse' + ucFirst(name)}><IntlText
                                                        messageId={`footer.analyses.${name}`}/></AppLink></li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                    <div className="col-sm-6">
                                        <ul>
                                            {
                                                ['why', 'press', 'about'/*, 'contact'*/].map((page, index) => (
                                                    <li key={index}><AppLink page={page}><IntlText
                                                        messageId={`footer.${page}`}/></AppLink></li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <ul id="site-footer-mail">
                                {
                                    ['press', 'asso', 'pro', 'individual'].map((type, index) => (
                                        <li key={index}><AppLink page={'contact' + ucFirst(type)}><span><FiMail/></span><br/><IntlText
                                            messageId={`footer.link.email.${type}`}/></AppLink></li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                    <div id="site-footer-legal" className="row">
                        <div className="col-12">
                            <div className="wrapper">
                                <ul>
                                    {
                                        ['legalNotice', 'cgv', 'privacyPolicy'].map((page, index) => (
                                            <li key={index}><AppLink page={page}><IntlText
                                                messageId={`footer.${page}`}/></AppLink></li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="site-footer-credits" className="container">
                <div className="row">
                    <div className="footer-address col-12">
                        <IntlText messageId={"footer.address"}/>
                    </div>
                    <div className="footer-line col-12"></div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <p>2020 &copy; toxSeek</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

Footer.displayName = 'Footer';

export default withLocalization(withDepartment(Footer));
