import React from "react"
import {withLocalization} from "../../Providers/LocalizationProvider";
import AppLink from "../AppLink";
import {withDepartment} from "../../Providers/DepartmentProvider";
import {withRouter} from "react-router-dom";

class CountrySelectorLink extends React.Component
{
    render() {

        const country = this.props.localization.country;
        const page = this.props.department.pages.countries;
        //const translated = this.props.routeConfig.hasOwnProperty('translated') ? this.props.routeConfig : true;
        const query = 'p=' + this.props.routeConfig.path

        return (
            <div className="country-selector-link">
                <AppLink page={page} query={query}>
                    <img src={`/images/flags/${country.code}_32.png`} title={`flag ${country.code}`} alt={"flag " + country.code} ></img>
                </AppLink>
            </div>
        )
    }
}

export default withLocalization(withDepartment(withRouter(CountrySelectorLink)));
