import PropTypes from 'prop-types'
import React from "react";
import LoginForm from "../Form/LoginForm";
import Popup from "./Popup";
import {injectIntl} from "react-intl";
import {sleep} from "../../utils/functions";
import {authLogin} from "../../Providers/AuthProvider/actions";
import {setProfile} from "../Account/actions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux"
import {createSelector} from "reselect";
import {makeSelectLocale} from "../../Providers/LanguageProvider/selectors";
import {withLocalization} from "../../Providers/LocalizationProvider";
import {withApi} from "../../Providers/ApiProvider";
import {withGA} from "../../Providers/ReactGAProvider";

const initialLoginSequences = [
    {id: 'account.login.sequence.connect', state: "account.login.sequence.state.notStarted", className: 'text-secondary'},
    {id: 'account.login.sequence.token', state: "account.login.sequence.state.notStarted", className: 'text-secondary'},
    {id: 'account.login.sequence.profile', state: "account.login.sequence.state.notStarted", className: 'text-secondary'},
    {id: 'account.login.sequence.done', state: "account.login.sequence.state.notStarted", className: 'text-secondary'},
];


class PopupLogin extends React.Component
{
    static propTypes = {
    dispatch: PropTypes.any.isRequired,
    history: PropTypes.any.isRequired,
    intl: PropTypes.any.isRequired,
    onHide: PropTypes.any.isRequired,
    redirectOnSuccess: PropTypes.any,
    show: PropTypes.any.isRequired
  }

  constructor(props) {
        super(props)

        this.onHide = this.onHide.bind(this);
        this.updateSequence = this.updateSequence.bind(this);
        this.onRecover = this.onRecover.bind(this);
        this.onLogin = this.onLogin.bind(this);

        this.state = {
            loginSequences: initialLoginSequences,
            lastError: null,
            action: 'login'
        };

        this.stateClass = {
            "account.login.sequence.state.notStarted": 'text-secondary',
            "account.login.sequence.state.started": 'text-warning',
            "account.login.sequence.state.ok": 'text-success',
            "account.login.sequence.state.ko": 'text-danger',
        };
    }


    onHide() {
        if (this.state.action !== 'info') {
            this.props.onHide();
            if (this.state.action === 'connected' && this.props.redirectOnSuccess) {
                this.props.history.push(this.props.redirectOnSuccess);
            }
            this.setState({action: 'login'});
        }
    };

    async updateSequence(id, state){
        state = 'account.login.sequence.state.' + state;
        const loginSequences = this.state.loginSequences.map((item) => {
            if (item.id === id) {
                return {id: id, state: state, className: this.stateClass[state]};
            } else {
                return item;
            }
        });
        this.setState({loginSequences: loginSequences});
    }

    async onRecover(email, error) {
        if (!email) {
            this.setState({action: 'recover'});
            return;
        }
        try {
            await this.setState({action: 'recovering', loginSequences: initialLoginSequences, lastError: null});
            await this.props.api.v1.recoverPassword(email, this.props.intl.locale, this.props.localization.origin );
            this.setState({action: 'recovered'});
        } catch (e) {
            await this.setState({action: 'recover', lastError: e.message});
        }

    }

    async onLogin(username, password) {

        let currentState = 'account.login.sequence.connect';
        try{
            if (!username) {
                await this.setState({action: 'login', loginSequences: initialLoginSequences, lastError: null});
                return;
            }

            await this.setState({action: 'info', loginSequences: initialLoginSequences, lastError: null});

            await this.updateSequence(currentState, 'started');
            await sleep(500);
            await this.updateSequence(currentState, 'ok');

            currentState = 'account.login.sequence.token';
            await this.updateSequence(currentState, 'started');
            const token = await this.props.api.v1.getToken(username, password);
            await this.props.dispatch(authLogin(token));
            await this.updateSequence(currentState, 'ok');

            currentState = 'account.login.sequence.profile';
            await this.updateSequence(currentState, 'started');
            const customer = await this.props.api.v1.getProfile();
            await this.props.dispatch(setProfile(customer));
            await this.updateSequence(currentState, 'ok');

            currentState = 'account.login.sequence.done';
            await this.updateSequence(currentState, 'started');
            await sleep(500);
            await this.updateSequence(currentState, 'ok');
            await this.setState({action: 'connected'});
            this.props.ga.event('Account','Login', 'User ' + customer.id + ' logged in successfully');
            this.props.ga.set({ userId: customer.id });
            this.onHide();
        } catch (e) {

            await this.updateSequence(currentState, 'ko');
            await this.setState({action: "loginError", lastError: e.message});
        }
    };

    render () {
        return (
            <Popup show={this.props.show}
                   onHide={this.onHide}
                   header={'modal.login.header'}
            >
                <LoginForm
                    action={this.state.action}
                    loginSequences={this.state.loginSequences}
                    onHide={this.onHide}
                    onLogin={this.onLogin}
                    onRecover={this.onRecover}
                    lastError={this.state.lastError}
                />
            </Popup>
        );
    }
}

const mapStateToProps = createSelector(
    makeSelectLocale(),
    locale => ({
        locale,
    }),
);

export default connect(mapStateToProps)(withGA(withLocalization(withRouter(injectIntl(withApi(PopupLogin))))));

