import produce from "immer";

const initialState = {
  customer: null,
  basket: {
    products: []
  }
};


function globalReducer (state = initialState, action) {

return produce(state, draft => {
    switch (action.type) {
      default:
    }
  });
}

globalReducer.displayName = 'globalReducer';

export default globalReducer;
