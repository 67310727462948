import React from 'react';
import Toolbar from "./Toolbar";
import Menu from "./Menu"
import {withLocalization} from "../Providers/LocalizationProvider"
import IntlImg from "./Intl/IntlImg";
import {withDepartment} from "../Providers/DepartmentProvider";
import InformationRibbon from "./InformationRibbon";

function Header(props) {

const href = props.localization.getUrl("/");

  return (
    <header id="site-header">
      <InformationRibbon/>
      <Toolbar routeConfig={props.routeConfig}/>
      <div id="menubar">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-2 col-6 logo-mobile">
              <div className="site-logo">
                <a href={href} className="default-logo"><IntlImg src={props.department.logo} alt="toxSeek logo"/></a>
              </div>
                <div className="mobile-logo">
                    <a href={href} className="default-logo"><IntlImg src="/images/logo-ts-no-baseline.png" alt="toxSeek logo"/></a>
                </div>
            </div>
            <div className="col-lg-10 col-md-10 col-6">
              <Menu routeConfig={props.routeConfig}/>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
Header.displayName = 'Header';

export default withLocalization(withDepartment(Header));
