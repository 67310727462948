import PropTypes from 'prop-types'
import React, {Fragment} from "react"
import Script from "react-load-script"
import {withLocalization} from "../LocalizationProvider";

const RecaptchaContext = React.createContext({
    execute: () => console.log('Reacaptcha context not initialized')
})

class RecaptchaProvider extends React.Component {
    static displayName = 'RecaptchaProvider';

    constructor(props) {
        super(props)
        this.googleRecaptchaSrc = 'https://www.google.com/recaptcha/api.js';
        this.state = {
            execute: undefined
        }
    }

    handleScriptLoad = () => {
        window.grecaptcha.ready(async () => {
            try {
                await this.setState({
                        execute: async (action) => {
                            let token;
                            try {
                                token = await window.grecaptcha.execute(this.props.reCaptchaKey, {action: action});
                            } catch (e) {
                                console.error(e);
                            }
                            return token;
                        }
                    }
                );
            } catch (e) {
                console.log(e);
            }
        })
    };

    render() {
        const url = `${this.googleRecaptchaSrc}?render=${this.props.reCaptchaKey}${
            this.props.localization.country.locale ? `&hl=${this.props.localization.country.locale}` : ''
        }`;

        let elements;
        if (this.state.execute) {
            elements = (
                <RecaptchaContext.Provider value={{...this.state}}>
                    {this.props.children}
                </RecaptchaContext.Provider>
            )
        }
        return (
            <Fragment>
                <Script url={url} onLoad={this.handleScriptLoad}/>
                {elements}
            </Fragment>
        );

    }
}

export default withLocalization(RecaptchaProvider);

export function withRecaptcha(WrappedElement) {

    return class extends React.Component {

        render() {
            return (
                <RecaptchaContext.Consumer>
                    {(context) => (
                        <WrappedElement recaptcha={context} {...this.props}/>
                    )}
                </RecaptchaContext.Consumer>
            )
        }
    }
}

RecaptchaProvider.propTypes = {
    language: PropTypes.any,
    reCaptchaKey: PropTypes.any.isRequired
};
