import React from 'react'
import IntlText from "./Intl/Text";
import {withCookies} from 'react-cookie';
import {Fade} from "react-bootstrap";
import {MdClose} from "react-icons/md"

class CookiesRibbon extends React.Component {
	static displayName = 'CookiesRibbon';
constructor(props) {
        super(props);
        this.state = {
            cookies: this.props.cookies.get('cookiesstate') || 'rejected',
            closed: false,
        };
    };

    handleAccept = () => {
        var expire = new Date();
        expire.setMonth(expire.getMonth() + 13);
       this.props.cookies.set('cookiesstate', 'accepted', {
           path: '/', // cookie path, use / as the path if you want your cookie to be accessible on all pages
           expires: expire, // (Date): absolute expiration date for the cookie
           //maxAge: 3600 * 24 * 365, // (number): relative max age of the cookie from when the client receives it in seconds
           domain: 'toxseek.com', // (string): domain for the cookie (sub.domain.com or .allsubdomains.com)
           //secure: false, // (boolean): Is only accessible through HTTPS?
            //httpOnly (boolean): Can only the server access the cookie?
           sameSite: true,// (boolean|none|lax|strict): Strict or Lax enforcement
       });
       window.location.reload();
       //this.setState({cookies: 'accepted'});
    }

    handleReject = () => {
        const allCookies = this.props.cookies.getAll({doNotParse: true});
        Object.keys(allCookies).forEach((name) => {
            this.props.cookies.remove(name);
        });

        window.location.reload();
    }

    render() {
        if ((this.state.cookies === 'rejected')  && !this.state.closed) {
            return (
                <Fade unmountOnExit in={5000}>
                    <div className="ribbon-container">
                        <div className="ribbon">
                            <div className="ribbon-text">
                                <IntlText messageId={'ribbon.cookie-question'}/>
                            </div>
                            <div className="tool-bar">
                                <button onClick={() => this.handleAccept()} className={'ribbon-button'}><IntlText messageId={'ribbon.button.accept'}/></button>

                            </div>
                            <div className="ribbon-close" onClick={() => this.setState({closed: true})}>
                                <MdClose/>
                            </div>
                        </div>
                    </div>
                </Fade>
            );
        }
        return null;
    }

}

export default withCookies(CookiesRibbon);

