/*
 *
 * LanguageProvider reducer
 *
 */

import produce from 'immer';

import {AUTH_LOGIN, AUTH_LOGOUT} from './constants';


export const initialState = {
    authentified: false,
    token: null
};

/* eslint-disable default-case, no-param-reassign */
const authProviderReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case AUTH_LOGIN:
                draft.token = action.token;
                draft.authentified = true;
                break;
            case AUTH_LOGOUT:
                draft.authentified = false;
                draft.token = null;
                break;
        }
    });

export default authProviderReducer;
