import PropTypes from 'prop-types'
import React from "react"
import {withLocalization} from "../../Providers/LocalizationProvider";
import {injectIntl} from "react-intl";

class IntlImg extends React.Component {
	static displayName = 'IntlImg';
static defaultProps = {
        alt: ""
    };

    static propTypes = {
        alt: PropTypes.any,
        src: PropTypes.any
    };

    render() {
        let {src, localization, alt, ...props} = this.props;
        const {code, locale} = localization.country;
        src = src.replace('{code}', code).replace('{locale}', locale);
        return (
            <img {...props} src={src} alt={this.props.intl.formatMessage({id: alt})}/>
        )
    }
}

export default withLocalization(injectIntl(IntlImg));
