import PropTypes from 'prop-types'
import React from "react"
import {NavHashLink as Link} from "react-router-hash-link";
import {withLocalization} from "../Providers/LocalizationProvider";
import {NavLink} from "react-router-dom";
import pages from "../pages"
import ClassNames from "classnames";
import {injectIntl} from "react-intl";
import {withGA} from "../Providers/ReactGAProvider";


class AppLink extends React.Component {
    static displayName = 'AppLink';
    static propTypes = {
        noWrapper: PropTypes.bool,
        children: PropTypes.any,
        hash: PropTypes.string,
        localization: PropTypes.any,
        page: PropTypes.string,
        pathname: PropTypes.string,
        query: PropTypes.string,
    };

    scroll(el) {
        setTimeout(() => el.scrollIntoView({behavior: 'smooth', block: 'start'}), 200);
    };

    handleClick = (e) => {
        if (this.props.gaEvent) {
            const {category, action, label} = this.props.gaEvent;
            this.props.ga.event(category, action, label);
        }
        if (this.props.disabled) e.preventDefault();
    };

    render() {
        const {getUrl} = this.props.localization;

        if (this.props.hash) {
            const classes = ClassNames('app-link', this.props.className);
            return (
                <div className={classes}>
                    <Link
                        //smooth
                        //scroll={this.scroll}
                        //scroll={el => {
                        //>    setTimeout(() => el.scrollIntoView({behavior: 'smooth', block: 'start'}), 500);
                        //}}
                        to={getUrl(this.props.pathname + this.props.hash)}>
                        {this.props.children}
                    </Link>
                </div>
            )
        } else {
            let path;
            if (this.props.page) {
                const routeCfg = pages[this.props.page];
                if (!routeCfg) {
                    console.error('Applink: unknown page ' + this.props.page);
                    return null;
                }
                const translated = routeCfg.hasOwnProperty('translated') ? routeCfg.translated : true;
                path = translated ? this.props.intl.formatMessage({id: routeCfg.path}) : routeCfg.path;
            } else {
                path = this.props.pathname;
            }
            path += this.props.query ? '?' + this.props.query : '';
            const classes = ClassNames('menu-item-link', this.props.className);
            if (this.props.noWrapper) {
                return (
                    <NavLink className={classes} onClick={this.handleClick} to={getUrl(path)}>
                        {this.props.children}
                    </NavLink>
                )
            } else {
                return (
                    <div className={"app-link"}>
                        <NavLink className={classes} onClick={this.handleClick} to={getUrl(path)}>
                            {this.props.children}
                        </NavLink>
                    </div>
                )
            }

        }
    }
}

export default withGA(injectIntl(withLocalization(AppLink)));
