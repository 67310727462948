import React from "react"
import "../scss/splash-screen.scss";

class SplashScreen extends React.Component {
	static displayName = 'SplashScreen';
constructor(props) {
        super(props);
        this.state = {
            show: !this.props.timeout,
        };
    }

    componentDidMount() {
        if(!this.state.show) {
            setTimeout(() => this.setState({show: true}), this.props.timeout);
        }
    }

    render() {

        return (
            <div className="splashscreen">
                {this.state.show ?
                    <img src="/images/splash-screen-single.gif" alt="animation d'attente" width={this.props.width || 'auto'}/>
                    : null
                }
            </div>
        )
    }
}

export default SplashScreen   
