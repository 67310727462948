/*
 *
 * LanguageProvider actions
 *
 */

import {AUTH_LOGIN, AUTH_LOGOUT} from './constants';

export function authLogin(token) {
    return {
        type: AUTH_LOGIN,
        token: token,
        authentified: true
    };
}


export function authLogout() {
    return {
        type: AUTH_LOGOUT,
    };
}


