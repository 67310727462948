/*
 *
 * LanguageProvider actions
 *
 */

import {SET_ORDERS, SET_PROFILE, SET_RESULTS} from './constants';

export function setProfile(profile) {
    return {
        type: SET_PROFILE,
        profile: profile
    };
}

export function setOrders(orders) {
    return {
        type: SET_ORDERS,
        orders: orders
    };
}

export function setResults(results) {
    return {
        type: SET_RESULTS,
        results: results
    };
}



