
export function sleep (milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}


export function cloneObject(object) {

    let result = {};
    Object.keys(object).forEach((property) => {
        if(typeof object[property] === 'object') {
            result[property] = cloneObject(object[property]);
        } else {
            result[property] = object[property];
        }
    });
    return result;
}


export function ucFirst(str) {
    if (str.length > 0) {
        return str[0].toUpperCase() + str.substring(1);
    } else {
        return str;
    }
}
