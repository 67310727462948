import PropTypes from 'prop-types'
import React from "react"
import {injectIntl} from "react-intl";
import {withLocalization} from "../../../Providers/LocalizationProvider";
import ReactMarkdown from "react-markdown"


class IntlText extends React.Component {
    static displayName = 'IntlText';
    static propTypes = {
        intl: PropTypes.object.isRequired,
        messageId: PropTypes.string.isRequired
    }

    constructor(props) {
        super(props);
        this.values = {
            lettrine: (...letter) => <span style={{fontSize: '2em'}} key={IntlText.getIndex()}>{letter}</span>,
            b: (...chunks) => <span style={{fontWeight: '900'}} key={IntlText.getIndex()}>{chunks}</span>,
            bb: (...chunks) => <span style={{fontWeight: '800'}} key={IntlText.getIndex()}>{chunks}</span>,
            orange: (...chunks) => <span style={{color: '#F13C11'}} key={IntlText.getIndex()}>{chunks}</span>,
            blue: (...chunks) => <span style={{color: '#171796'}} key={IntlText.getIndex()}>{chunks}</span>,
            br: <br/>,
            dash: <span className={'dash'}> - </span>,
            i: text => <span style={{fontStyle: 'italic'}} key={IntlText.getIndex()}>{text}</span>,
            nw: (chunks) => <span style={{whiteSpace: "nowrap", display: "inline-block"}}
                                  key={IntlText.getIndex()}>{chunks}</span>,
            p: (chunks) => <p key={IntlText.getIndex()}>{chunks}</p>,
            http: url => <a href={"http://" + url} key={IntlText.getIndex()}>{url}</a>,
            https: url => <a href={"https://" + url} key={IntlText.getIndex()}>{url}</a>,
            mailto: email => <a href={"mailto:" + email} key={IntlText.getIndex()}>{email}</a>,
            md: (source) => (
                <ReactMarkdown unwrapDisallowed renderers={{paragraph: props => <span {...props} />}}
                               key={IntlText.getIndex()}>
                    {source}
                </ReactMarkdown>)
        };

    }

    static index = 0;

    static getIndex = () => {
        return IntlText.index++ + Math.random();
    };

    render() {
        return this.props.intl.formatMessage({id: this.props.messageId}, {
            ...this.props.context,
            ...this.values,
            herelocalizedfilepdf: url => <a href={url + this.props.localization.country.locale + '.pdf'}
                                            key={IntlText.getIndex()}>{this.props.intl.formatMessage({id: 'common.here'})}</a>,
        });
    }
}

export default withLocalization(injectIntl(IntlText));
