import React from 'react';
import ReactGA from "react-ga4";
import {withLocalization} from "./LocalizationProvider";
import environment from "../environment";
import {withLogin} from "./LoginProvider";
import {withCookies} from 'react-cookie';

const ReactGAContext = React.createContext({
    event: (category, action, label, value = 0, interactive = true) => {
        console.log('LoginContext was not correctly initialized.')
    },
    orderEvent: (action, orderCode, value = 0) => {
        console.log('LoginContext was not correctly initialized.')
    },
    exception: (message, fatal = false) => {
    },
    pageview: (path, trackeNames, title) => {
    },
    set: (options) => {
    },
});

class ReactGAProvider extends React.Component {
    static displayName = 'ReactGAProvider';

    constructor(props) {
        super(props);

        this.state = {
            event: this.event,
            orderEvent: this.orderEvent,
            exception: this.exception,
            pageview: this.pageview,
            set: this.set,
        };
        let gaOptions = {};

        if (this.props.login.isLoggedIn()) {
            gaOptions.userId = this.props.login.getProfile().id;
        }
        this.cookieEnabled = (this.props.cookies.get('cookiesstate') || 'rejected') === 'accepted';

        const snap = navigator.userAgent !== 'react-snap';
        const production = process.env.NODE_ENV === 'production';
        if (production && snap) {
            ReactGA.initialize(environment.ga_id, {
                debug: (environment.environment !== 'production'),
                gaOptions
            });
        }
    }

    set = (options) => {
        if (!this.cookieEnabled) return;
        ReactGA.set(options);
    }

    pageview = (path, trackeNames, title) => {
        if (!this.cookieEnabled) return;
        //ReactGA.pageview(path, trackeNames, title);
        ReactGA.send({ hitType: "pageview", page: path, title: title });
    }

    event = (category, action, label, value, interactive = true) => {
        if (!this.cookieEnabled) return;
        let prefix = '';
        if (this.props.login.isLoggedIn()) {
            prefix = 'User ' + this.props.login.getProfile().id + ': ';
        }
        let event = {
            category: category,
            action: action,
            label: prefix + label,
            nonInteraction: !interactive,
        };
        if (value) {
            event.value = parseInt(value);
        }
        ReactGA.event(event);
    };

    orderEvent(action, orderCode, value) {
        this.event('Order', action, 'Order code ' + orderCode, value);
    }

    exception = (description, fatal = false) => {
        if (!this.cookieEnabled) return;
        ReactGA.exception({
            description: description,
            fatal: fatal
        });
    };

    render() {

        return (
            <ReactGAContext.Provider value={{...this.state}}>
                {this.props.children}
            </ReactGAContext.Provider>
        );

    }
}

export default withLogin(withLocalization(withCookies(ReactGAProvider)));

export function withGA(WrappedElement) {

    return class extends React.Component {
        render() {
            return (
                <ReactGAContext.Consumer>
                    {(context) => (
                        <WrappedElement ga={context} {...this.props}/>
                    )}
                </ReactGAContext.Consumer>
            )
        }
    }
}

