import React from "react"
import {DEFAULT_DEPARTMENT} from "../department";

const DepartmentContext = new React.createContext(
    DEFAULT_DEPARTMENT,
);

class DepartmentProvider extends React.Component {
    static displayName = 'DepartmentProvider';
    constructor(props) {
        super(props);
        this.state = {
            department: this.props.department,

        }
    }
    render() {

        return (
            <DepartmentContext.Provider value={this.state.department}>
                {this.props.children}
            </DepartmentContext.Provider>
        )
    }
}

export default DepartmentProvider;

export function withDepartment(WrappedElement) {

    return class extends React.Component {

        render() {
            return (
                <DepartmentContext.Consumer>
                    {(context) => (
                        <WrappedElement department={context} {...this.props}/>
                    )}
                </DepartmentContext.Consumer>
            )
        }
    }
}
