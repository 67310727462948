import React from 'react'
import {Helmet} from 'react-helmet';
import LoginProvider from "../../Providers/LoginProvider"
import RouteManager from "../RouteManager";
import {injectIntl} from "react-intl";
import {withLocalization} from "../../Providers/LocalizationProvider";
import ReactGAProvider from "../../Providers/ReactGAProvider";

class App extends React.Component {
	static displayName = 'App';
render() {
        this.props.localization.setIntl(this.props.intl);
        const lang = this.props.localization.country.locale || 'en-GB';
        return (
            <div className="App">
                <Helmet titleTemplate="%s - toxSeek" defaultTitle="Home" htmlAttributes={{lang: lang, 'xml:lang': lang, dir: 'ltr'}}>
                    <meta name="description" content="toxSeek website"/>
                    <meta httpEquiv="Content-Language" content={lang}/>
                </Helmet>
                <LoginProvider>
                    <ReactGAProvider>
                        <RouteManager/>
                    </ReactGAProvider>
                </LoginProvider>
            </div>
        );
    }
}

export default withLocalization(injectIntl(App));
