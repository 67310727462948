/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React, {lazy} from 'react';
import {Route, Switch, withRouter} from "react-router-dom";
import {withLocalization} from "../Providers/LocalizationProvider";
import pages from "../pages"
import {injectIntl} from "react-intl";
import withTracker from "../Providers/withTracker";
import SplashScreen from "./SplashScreen";
import Layout from "./Layout";
import {DEFAULT_DEPARTMENT} from "../department";

const NotFoundPage = lazy(() => import("../containers/NotFoundPage"));
const PrivateRoute = lazy(() => import("./PrivateRoute"));

class RouteManager extends React.Component {
    static displayName = 'RouteManager';

    constructor(props) {
        super(props);
        this.state = {
            showCountrySelector: false

        };
    }

    withLayout = (routeCfg) => {
        const {component} = routeCfg;
        return (props) => <Layout {...props} component={withTracker(component)}
                                  department={routeCfg.department || DEFAULT_DEPARTMENT} routeConfig={routeCfg}/>;
    }

    render() {

        const currentCountryId = this.props.localization.country.id;

        if (!currentCountryId) {
            return (<SplashScreen/>);
        } else {


            let routes = [];
            //Object.keys(countries).forEach((countryId) => {
            //const country = countries[countryId]
            const {country} = this.props.localization;
            Object.keys(pages).forEach((page, index) => {
                const routeCfg = pages[page];
                const RouteComponent = routeCfg.private ? PrivateRoute : Route;
                const translated = routeCfg.hasOwnProperty('translated') ? routeCfg.translated : true;

                const path = translated ? this.props.intl.formatMessage({id: routeCfg.path}) : routeCfg.path;
                const component = this.withLayout(routeCfg);
                routes.push(
                    <RouteComponent exact key={routes.length} path={country.path + path}
                                    component={component}
                    />
                );
                if (routeCfg.hasOwnProperty('unlocalizedPath') && routeCfg.unlocalizedPath) {
                    //console.log('Add unlocalized path: ' + routeCfg.unlocalizedPath);
                    routes.push(
                        <RouteComponent exact key={routes.length}
                                        path={country.path + routeCfg.unlocalizedPath}
                                        component={component}/>
                    );
                }
            });
            //});

            routes.push(<Route key={routes.length} path={'*'} component={this.withLayout({
                component: NotFoundPage,
                department: DEFAULT_DEPARTMENT,
            })}/>);

            return (
                <Switch>
                    {routes}
                </Switch>
            );
        }
    }
}

export default injectIntl(withLocalization(withRouter(RouteManager)));

