import React from "react";
import AppLink from "./AppLink";
import IntlText from "./Intl/Text";
import CountrySelectorLink from "./CountrySelectorLink";
import {withDepartment} from "../Providers/DepartmentProvider"

class Toolbar extends React.Component {
    static displayName = 'Toolbar';

    buildMenuItem = (menuConfig, index) => {
        menuConfig.className = menuConfig.hasOwnProperty('className') ? menuConfig.className + ' toolbar-item' : 'toolbar-item';
        let component;

        if (menuConfig.hasOwnProperty('component')) {
            menuConfig.key = index;
            component = menuConfig.component(menuConfig);
        } else {
            component = <AppLink key={index}{...menuConfig}>
                <IntlText messageId={menuConfig.messageId}/>
            </AppLink>
        }

        return component;
    };


    render() {

        const {toolbar} = this.props.department.menus;
        let items = toolbar.map(this.buildMenuItem);
        items.push(<CountrySelectorLink key={items.length} routeConfig={this.props.routeConfig}/>);

        return (
            <div className="toolbar">
                <div className="toolbar-container">
                    {items}
                </div>
            </div>
        );
    }
}


export default withDepartment(Toolbar);
