import React from "react"
import {countries, DEFAULT_COUNTRY} from "../../countries";
import {withRouter} from "react-router-dom"
import LocaleSelectorPage from "../../containers/LocaleSelectorPage";
import pages from "../../pages"

const LocalizationContext = new React.createContext({
    country: DEFAULT_COUNTRY
});

class LocalizationProvider extends React.Component {
	static displayName = 'LocalizationProvider';
constructor(props) {
        super(props)
        this.currentCountry = DEFAULT_COUNTRY;
        this.intl = undefined;
    }
    setIntl = (intl) => {
        this.intl = intl;
    }
    getCountryIdFromUrl = () => {
        const regex = new RegExp("^\\/([a-z]{2}|[a-z]{4})(|\\/|\\/.+)$");
        const item = regex.exec(this.props.location.pathname);
        return item ? item[1] : DEFAULT_COUNTRY.code;
    }

    redirectTo = (path) => {
        this.props.history.push(this.getUrl(path));
    };

    getPageUrl = (pageId) => {
        const page = pages[pageId];
        if (page) {
            const translated = page.hasOwnProperty('translated') ? page.translated : true;
            return translated ? this.intl.formatMessage({id: page.path}) : page.path;
        }
        return '/';
    }

    redirectToPage = (pageId, queryString) => {
        const page = pages[pageId];
        if (page) {
            this.redirectTo(this.getPageUrl(pageId) + (queryString ? '?' + queryString : ''));
        }
    }

    getUrl = (path) => {
        return this.currentCountry.path + path;
    }

    render() {
        const countryId = this.getCountryIdFromUrl();
        this.currentCountry = countries[countryId];

        if (! this.currentCountry) {
            this.currentCountry = DEFAULT_COUNTRY;
        }

        let elements = this.props.children;

        if ( typeof this.currentCountry.locale === 'object') {

            elements = (<LocaleSelectorPage />);
        }
        let origin = window.location.origin;
        if (! origin) {
            origin = 'https://toxseek.com'
        }
        return (
            <LocalizationContext.Provider value={{
                country: this.currentCountry,
                redirectTo: this.redirectTo,
                redirectToPage: this.redirectToPage,
                getPageUrl: this.getPageUrl,
                getUrl: this.getUrl,
                origin: origin + this.currentCountry.path,
                setIntl: this.setIntl,
            }}>
                {elements}
            </LocalizationContext.Provider>
        )
    }
}

export default withRouter(LocalizationProvider);

export function withLocalization(WrappedElement) {

    return class extends React.Component {

        render() {
            return (
                <LocalizationContext.Consumer>
                    {(context) => (
                        <WrappedElement localization={context} {...this.props}/>
                    )}
                </LocalizationContext.Consumer>
            )
        }
    }
}
