/*
 *
 * AuthProvider constants
 *
 */

export const SET_PROFILE = 'account/PROFILE';

export const SET_ORDERS = 'account/ORDERS';

export const SET_RESULTS = 'account/RESULTS';

