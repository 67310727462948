import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import ReactDOM from 'react-dom';
import Bootstrap from "./components/Bootstrap";

const MOUNT_NODE = document.getElementById('root');

const render = function () {
    const app = (<Bootstrap/>);

    if (MOUNT_NODE.hasChildNodes()) {
        ReactDOM.hydrate(app, MOUNT_NODE);
    } else {
        ReactDOM.render(app, MOUNT_NODE);
    }
};

if (module.hot) {
    // Hot reloadable React components and translation json files
    // modules.hot.accept does not accept dynamic dependencies,
    // have to be constants at compile-time
    module.hot.accept(['./i18n', './components/App'], () => {
        ReactDOM.unmountComponentAtNode(MOUNT_NODE);
        render();
    });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
    new Promise(resolve => {
        resolve(import('intl'));
    })
        .then(() => render())
        .catch(err => {
            throw err;
        });
} else {
    render();
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
