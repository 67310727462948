import React from "react"
import {withRouter} from "react-router-dom"


class ScrollToTop extends React.Component {
	static displayName = 'ScrollToTop';
componentDidUpdate(prevProps) {
        if ((this.props.location.pathname !== prevProps.location.pathname) && !this.props.location.hash) {
            //window.scrollTo(0, 0);
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 250);
        }
    }

    render() {
        return this.props.children;
    }
}

export default withRouter(ScrollToTop);
