import React from "react"
import IntlText from "../../../Intl/Text";


class StateSequence extends React.Component {
	static displayName = 'StateSequence';
render() {
        const items = this.props.sequences.map((message) => {
           return (
                <tr key={message.id}>
                    <td className={'text-primary text-left text-nowrap pr-3'}><IntlText messageId={message.id}/></td>
                    <td className={message.className + ' text-right text-nowrap'}><IntlText messageId={message.state}/></td>
                </tr>
            );
        });
        return (
            <table className={'w-100'}>
                <tbody>
                    {items}
                </tbody>
            </table>
        );
    }
}

export default StateSequence;
