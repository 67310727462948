import {createSelector} from 'reselect';
import {initialState} from './reducer';

/**
 * Direct selector to the language domain
 */

const selectAuth = state => state.auth || initialState;

/**
 * Select the language locale
 */

const makeSelectAuth = createSelector(
        selectAuth,
        authState => authState,
    );

export { selectAuth, makeSelectAuth};
