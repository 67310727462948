
// Croatie, Rep. tchek, Grèce, hongrie, pologne, roumanie, turquie

export const countries = {

    chde: {id: 'chde', continent: "europe", path: '/chde', code: 'ch', name: "Schweiz", locale: 'de-DE', lang: 'de-CH'},
    chfr: {id: 'chfr', continent: "europe", path: '/chfr', code: 'ch', name: "Suisse", locale: 'fr-FR'},
    lude: {id: 'lude', continent: "europe", path: '/lude', code: 'lu', name: "Luxembourg (de)", locale: 'de-DE'},
    lufr: {id: 'lufr', continent: "europe", path: '/lufr', code: 'lu', name: "Luxembourg (fr)", locale: 'fr-FR'},
    cafr: {id: "cafr", continent: "america",path: "/cafr", code: "ca", name: "Canada (Fr)", locale: 'fr-FR'},
    caen: {id: "caen", continent: "america",path: "/caen", code: "ca", name: "Canada (En)", locale: 'en-GB'},
    befr: {id: 'befr', continent: "europe", path: '/befr', code: 'be', name: "Belgique (Fr)", locale: 'fr-FR'},
    been: {id: 'been', continent: "europe", path: '/been', code: 'be', name: "Belgium (En)", locale: 'en-GB'},

    //af: {id: "af", continent: "asia", code: "af", path: "/af", name: "afghanistan", locale: 'en-GB'},
    //am: {id: "am", continent: "asia", code: "am", path: "/am", name: "armenia", locale: 'en-GB'},
    //az: {id: "az", continent: "asia", code: "az", path: "/az", name: "azerbaijan", locale: 'en-GB'},
    //bh: {id: "bh", continent: "asia", code: "bh", path: "/bh", name: "bahrain", locale: 'en-GB'},
    //bd: {id: "bd", continent: "asia", code: "bd", path: "/bd", name: "bangladesh", locale: 'en-GB'},
    //bt: {id: "bt", continent: "asia", code: "bt", path: "/bt", name: "bhutan", locale: 'en-GB'},
    //io: {id: "io", continent: "asia", code: "io", path: "/io", name: "british indian ocean territory", locale: 'en-GB'},
    //bn: {id: "bn", continent: "asia", code: "bn", path: "/bn", name: "brunei", locale: 'en-GB'},
    //kh: {id: "kh", continent: "asia", code: "kh", path: "/kh", name: "cambodia", locale: 'en-GB'},
    //cn: {id: "cn", continent: "asia", code: "cn", path: "/cn", name: "china", locale: 'en-GB'},
    //cx: {id: "cx", continent: "asia", code: "cx", path: "/cx", name: "christmas island", locale: 'en-GB'},
    //cc: {id: "cc", continent: "asia", code: "cc", path: "/cc", name: "cocos (keeling) islands", locale: 'en-GB'},
    // ge: {id: "ge", continent: "asia", code: "ge", path: "/ge", name: "georgia", locale: 'en-GB'},
    // hk: {id: "hk", continent: "asia", code: "hk", path: "/hk", name: "hong kong sar china", locale: 'en-GB'},
    in: {id: "in", continent: "asia", code: "in", path: "/in", name: "india", locale: 'en-GB', cn23: true, tva: 0},
    // id: {id: "id", continent: "asia", code: "id", path: "/id", name: "indonesia", locale: 'en-GB'},
    // ir: {id: "ir", continent: "asia", code: "ir", path: "/ir", name: "iran", locale: 'en-GB'},
    // iq: {id: "iq", continent: "asia", code: "iq", path: "/iq", name: "iraq", locale: 'en-GB'},
    il: {id: "il", continent: "asia", code: "il", path: "/il", name: "israel", locale: 'en-GB', cn23: true, tva: 0},
    jp: {id: "jp", continent: "asia", code: "jp", path: "/jp", name: "japan", locale: 'en-GB', cn23: true, tva: 0},
    // jo: {id: "jo", continent: "asia", code: "jo", path: "/jo", name: "jordan", locale: 'en-GB'},
    // kz: {id: "kz", continent: "asia", code: "kz", path: "/kz", name: "kazakhstan", locale: 'en-GB'},
    // kw: {id: "kw", continent: "asia", code: "kw", path: "/kw", name: "kuwait", locale: 'en-GB'},
    // kg: {id: "kg", continent: "asia", code: "kg", path: "/kg", name: "kyrgyzstan", locale: 'en-GB'},
    // la: {id: "la", continent: "asia", code: "la", path: "/la", name: "laos", locale: 'en-GB'},
    // lb: {id: "lb", continent: "asia", code: "lb", path: "/lb", name: "lebanon", locale: 'en-GB'},
    // mo: {id: "mo", continent: "asia", code: "mo", path: "/mo", name: "macau sar china", locale: 'en-GB'},
    // my: {id: "my", continent: "asia", code: "my", path: "/my", name: "malaysia", locale: 'en-GB'},
    // mv: {id: "mv", continent: "asia", code: "mv", path: "/mv", name: "maldives", locale: 'en-GB'},
    // fm: {id: "fm", continent: "asia", code: "fm", path: "/fm", name: "micronesia", locale: 'en-GB'},
    // mn: {id: "mn", continent: "asia", code: "mn", path: "/mn", name: "mongolia", locale: 'en-GB'},
    // mm: {id: "mm", continent: "asia", code: "mm", path: "/mm", name: "myanmar (burma)", locale: 'en-GB'},
    // np: {id: "np", continent: "asia", code: "np", path: "/np", name: "nepal", locale: 'en-GB'},
    // kp: {id: "kp", continent: "asia", code: "kp", path: "/kp", name: "north korea", locale: 'en-GB'},
    // om: {id: "om", continent: "asia", code: "om", path: "/om", name: "oman", locale: 'en-GB'},
    // pk: {id: "pk", continent: "asia", code: "pk", path: "/pk", name: "pakistan", locale: 'en-GB'},
    // ps: {id: "ps", continent: "asia", code: "ps", path: "/ps", name: "palestinian territories", locale: 'en-GB'},
    // ph: {id: "ph", continent: "asia", code: "ph", path: "/ph", name: "philippines", locale: 'en-GB'},
    // qa: {id: "qa", continent: "asia", code: "qa", path: "/qa", name: "qatar", locale: 'en-GB'},
     ru: {id: "ru", continent: "asia", code: "ru", path: "/ru", name: "russia", locale: 'en-GB', cn23: true, tva: 0},
    // sa: {id: "sa", continent: "asia", code: "sa", path: "/sa", name: "saudi arabia", locale: 'en-GB'},
    // sg: {id: "sg", continent: "asia", code: "sg", path: "/sg", name: "singapore", locale: 'en-GB'},
    // kr: {id: "kr", continent: "asia", code: "kr", path: "/kr", name: "south korea", locale: 'en-GB'},
    // lk: {id: "lk", continent: "asia", code: "lk", path: "/lk", name: "sri lanka", locale: 'en-GB'},
    // sy: {id: "sy", continent: "asia", code: "sy", path: "/sy", name: "syria", locale: 'en-GB'},
    // tw: {id: "tw", continent: "asia", code: "tw", path: "/tw", name: "taiwan", locale: 'en-GB'},
    // tj: {id: "tj", continent: "asia", code: "tj", path: "/tj", name: "tajikistan", locale: 'en-GB'},
    // th: {id: "th", continent: "asia", code: "th", path: "/th", name: "thailand", locale: 'en-GB'},
    // tl: {id: "tl", continent: "asia", code: "tl", path: "/tl", name: "timor-leste", locale: 'en-GB'},
    // tm: {id: "tm", continent: "asia", code: "tm", path: "/tm", name: "turkmenistan", locale: 'en-GB'},
    // ae: {id: "ae", continent: "asia", code: "ae", path: "/ae", name: "united arab emirates", locale: 'en-GB'},
    // uz: {id: "uz", continent: "asia", code: "uz", path: "/uz", name: "uzbekistan", locale: 'en-GB'},
    // vn: {id: "vn", continent: "asia", code: "vn", path: "/vn", name: "vietnam", locale: 'en-GB'},
    // ax: {id: "ax", continent: "europe", code: "ax", path: "/ax", name: "åland islands", locale: 'en-GB'},
    // al: {id: "al", continent: "europe", code: "al", path: "/al", name: "albania", locale: 'en-GB'},
    // ad: {id: "ad", continent: "europe", code: "ad", path: "/ad", name: "andorra", locale: 'en-GB'},
    at: {id: "at", continent: "europe", code: "at", path: "/at", name: "austria", locale: 'de-DE', cn23: false, tva: 20},
    // by: {id: "by", continent: "europe", code: "by", path: "/by", name: "belarus", locale: 'en-GB'},
    be: {id: "be", continent: "europe", code: "be", path: "/be", name: "belgium", cn23: false, tva: 20,
        locale: {
            fr: {message: 'Choisissez votre langue', label: 'Français'},
            en: {message: 'Choose your language', label: 'English'}
        }},
    // ba: {id: "ba", continent: "europe", code: "ba", path: "/ba", name: "bosnia and herzegovina", locale: 'en-GB'},
    // bg: {id: "bg", continent: "europe", code: "bg", path: "/bg", name: "bulgaria", locale: 'en-GB'},
    //hr: {id: "hr", continent: "europe", code: "hr", path: "/hr", name: "croatia", locale: 'en-GB', cn23: false, tva: 20},
    // cy: {id: "cy", continent: "europe", code: "cy", path: "/cy", name: "cyprus", locale: 'en-GB'},
    //cz: {id: "cz", continent: "europe", code: "cz", path: "/cz", name: "czech republic", locale: 'en-GB', cn23: false, tva: 20},
     dk: {id: "dk", continent: "europe", code: "dk", path: "/dk", name: "denmark", locale: 'en-GB', cn23: false, tva: 20},
    // ee: {id: "ee", continent: "europe", code: "ee", path: "/ee", name: "estonia", locale: 'en-GB'},
    // fo: {id: "fo", continent: "europe", code: "fo", path: "/fo", name: "faroe islands", locale: 'en-GB'},
    // fi: {id: "fi", continent: "europe", code: "fi", path: "/fi", name: "finland", locale: 'en-GB'},
    fr: {id: "fr", continent: "europe", code: "fr", path: "/fr", name: "france", locale: 'fr-FR', cn23: false, tva: 20},
    de: {id: "de", continent: "europe", code: "de", path: "/de", name: "germany", locale: 'de-DE', cn23: false, tva: 20},
    // gi: {id: "gi", continent: "europe", code: "gi", path: "/gi", name: "gibraltar", locale: 'en-GB'},
    gr: {id: "gr", continent: "europe", code: "gr", path: "/gr", name: "greece", locale: 'en-GB', cn23: false, tva: 20},
    //hu: {id: "hu", continent: "europe", code: "hu", path: "/hu", name: "hungary", locale: 'en-GB', cn23: false, tva: 20},
    //is: {id: "is", continent: "europe", code: "is", path: "/is", name: "iceland", locale: 'en-GB', cn23: true, tva: 0},
    ie: {id: "ie", continent: "europe", code: "ie", path: "/ie", name: "ireland", locale: 'en-GB', cn23: false, tva: 20},
    // im: {id: "im", continent: "europe", code: "im", path: "/im", name: "isle of man", locale: 'en-GB'},
    it: {id: "it", continent: "europe", code: "it", path: "/it", name: "italy", locale: 'en-GB', cn23: false, tva: 20},
    // je: {id: "je", continent: "europe", code: "je", path: "/je", name: "jersey", locale: 'en-GB'},
    // li: {id: "li", continent: "europe", code: "li", path: "/li", name: "liechtenstein", locale: 'en-GB'},
    // lv: {id: "lv", continent: "europe", code: "lv", path: "/lv", name: "latvia", locale: 'en-GB'},
    // lt: {id: "lt", continent: "europe", code: "lt", path: "/lt", name: "lithuania", locale: 'en-GB'},
    lu: {id: "lu", continent: "europe", code: "lu", path: "/lu", name: "luxembourg", cn23: false, tva: 20,
        locale: {
            fr: {message: 'Choisissez votre langue', label: 'Français'},
            de: {message: 'Wählen Sie Ihre Sprache', label: 'Deutsche'}
        }},
    // gg: {id: "gg", continent: "europe", code: "gg", path: "/gg", name: "guernsey", locale: 'en-GB'},
    // mk: {id: "mk", continent: "europe", code: "mk", path: "/mk", name: "macedonia", locale: 'en-GB'},
    // mt: {id: "mt", continent: "europe", code: "mt", path: "/mt", name: "malta", locale: 'en-GB'},
    // md: {id: "md", continent: "europe", code: "md", path: "/md", name: "moldova", locale: 'en-GB'},
    mc: {id: "mc", continent: "europe", code: "mc", path: "/mc", name: "monaco", locale: 'fr-FR', cn23: false, tva: 20},
    // me: {id: "me", continent: "europe", code: "me", path: "/me", name: "montenegro", locale: 'en-GB'},
    nl: {id: "nl", continent: "europe", code: "nl", path: "/nl", name: "netherlands", locale: 'en-GB', cn23: false, tva: 20},
    no: {id: "no", continent: "europe", code: "no", path: "/no", name: "norway", locale: 'en-GB', cn23: true, tva: 0},
    pl: {id: "pl", continent: "europe", code: "pl", path: "/pl", name: "poland", locale: 'en-GB', cn23: false, tva: 20},
    pt: {id: "pt", continent: "europe", code: "pt", path: "/pt", name: "portugal", locale: 'en-GB', cn23: false, tva: 20},
    //ro: {id: "ro", continent: "europe", code: "ro", path: "/ro", name: "romania", locale: 'en-GB', cn23: false, tva: 20},
    // sm: {id: "sm", continent: "europe", code: "sm", path: "/sm", name: "san marino", locale: 'en-GB'},
    // rs: {id: "rs", continent: "europe", code: "rs", path: "/rs", name: "serbia", locale: 'en-GB'},
    // sk: {id: "sk", continent: "europe", code: "sk", path: "/sk", name: "slovakia", locale: 'en-GB'},
    si: {id: "si", continent: "europe", code: "si", path: "/si", name: "slovenia", locale: 'en-GB', cn23: false, tva: 20},
    es: {id: "es", continent: "europe", code: "es", path: "/es", name: "spain", locale: 'es-ES', cn23: false, tva: 20},
    // sj: {id: "sj", continent: "europe", code: "sj", path: "/sj", name: "svalbard and jan mayen", locale: 'en-GB'},
    se: {id: "se", continent: "europe", code: "se", path: "/se", name: "sweden", locale: 'en-GB', cn23: true, tva: 0},
    ch: {id: "ch", continent: "europe", code: "ch", path: "/ch", name: "switzerland", cn23: true, tva: 0,
         locale: {
             fr: {message: 'Choisissez votre langue', label: 'Français'},
             de: {message: 'Wählen Sie Ihre Sprache', label: 'Deutsche'}
         }},
    //tr: {id: "tr", continent: "europe", code: "tr", path: "/tr", name: "turkey", locale: 'en-GB', cn23: true, tva: 0},
    // ua: {id: "ua", continent: "europe", code: "ua", path: "/ua", name: "ukraine", locale: 'en-GB'},
    gb: {id: "gb", continent: "europe", code: "gb", path: "/gb", name: "united kingdom", locale: 'en-GB', cn23: false, tva: 20},
    // va: {id: "va", continent: "europe", code: "va", path: "/va", name: "vatican city", locale: 'en-GB'},
    // dz: {id: "dz", continent: "africa", code: "dz", path: "/dz", name: "algeria", locale: 'en-GB'},
    // bj: {id: "bj", continent: "africa", code: "bj", path: "/bj", name: "benin", locale: 'en-GB'},
    // ao: {id: "ao", continent: "africa", code: "ao", path: "/ao", name: "angola", locale: 'en-GB'},
    // bw: {id: "bw", continent: "africa", code: "bw", path: "/bw", name: "botswana", locale: 'en-GB'},
    // bf: {id: "bf", continent: "africa", code: "bf", path: "/bf", name: "burkina faso", locale: 'en-GB'},
    // bi: {id: "bi", continent: "africa", code: "bi", path: "/bi", name: "burundi", locale: 'en-GB'},
    // cm: {id: "cm", continent: "africa", code: "cm", path: "/cm", name: "cameroon", locale: 'en-GB'},
    // cv: {id: "cv", continent: "africa", code: "cv", path: "/cv", name: "cape verde", locale: 'en-GB'},
    // cf: {id: "cf", continent: "africa", code: "cf", path: "/cf", name: "central african republic", locale: 'en-GB'},
    // td: {id: "td", continent: "africa", code: "td", path: "/td", name: "chad", locale: 'en-GB'},
    // km: {id: "km", continent: "africa", code: "km", path: "/km", name: "comoros", locale: 'en-GB'},
    // cg: {id: "cg", continent: "africa", code: "cg", path: "/cg", name: "congo - brazzaville", locale: 'en-GB'},
    // cd: {id: "cd", continent: "africa", code: "cd", path: "/cd", name: "congo - kinshasa", locale: 'en-GB'},
    // ci: {id: "ci", continent: "africa", code: "ci", path: "/ci", name: "côte d’ivoire", locale: 'en-GB'},
    // dj: {id: "dj", continent: "africa", code: "dj", path: "/dj", name: "djibouti", locale: 'en-GB'},
    // eg: {id: "eg", continent: "africa", code: "eg", path: "/eg", name: "egypt", locale: 'en-GB'},
    // gq: {id: "gq", continent: "africa", code: "gq", path: "/gq", name: "equatorial guinea", locale: 'en-GB'},
    // er: {id: "er", continent: "africa", code: "er", path: "/er", name: "eritrea", locale: 'en-GB'},
    // et: {id: "et", continent: "africa", code: "et", path: "/et", name: "ethiopia", locale: 'en-GB'},
    // ga: {id: "ga", continent: "africa", code: "ga", path: "/ga", name: "gabon", locale: 'en-GB'},
    // gm: {id: "gm", continent: "africa", code: "gm", path: "/gm", name: "gambia", locale: 'en-GB'},
    // gh: {id: "gh", continent: "africa", code: "gh", path: "/gh", name: "ghana", locale: 'en-GB'},
    // ls: {id: "ls", continent: "africa", code: "ls", path: "/ls", name: "lesotho", locale: 'en-GB'},
    // lr: {id: "lr", continent: "africa", code: "lr", path: "/lr", name: "liberia", locale: 'en-GB'},
    // ly: {id: "ly", continent: "africa", code: "ly", path: "/ly", name: "libya", locale: 'en-GB'},
    // mg: {id: "mg", continent: "africa", code: "mg", path: "/mg", name: "madagascar", locale: 'en-GB'},
    // mw: {id: "mw", continent: "africa", code: "mw", path: "/mw", name: "malawi", locale: 'en-GB'},
    // ml: {id: "ml", continent: "africa", code: "ml", path: "/ml", name: "mali", locale: 'en-GB'},
    // mr: {id: "mr", continent: "africa", code: "mr", path: "/mr", name: "mauritania", locale: 'en-GB'},
    // mu: {id: "mu", continent: "africa", code: "mu", path: "/mu", name: "mauritius", locale: 'en-GB'},
    // yt: {id: "yt", continent: "africa", code: "yt", path: "/yt", name: "mayotte", locale: 'en-GB'},
    // ma: {id: "ma", continent: "africa", code: "ma", path: "/ma", name: "morocco", locale: 'en-GB'},
    // mz: {id: "mz", continent: "africa", code: "mz", path: "/mz", name: "mozambique", locale: 'en-GB'},
    // na: {id: "na", continent: "africa", code: "na", path: "/na", name: "namibia", locale: 'en-GB'},
    // ne: {id: "ne", continent: "africa", code: "ne", path: "/ne", name: "niger", locale: 'en-GB'},
    // ng: {id: "ng", continent: "africa", code: "ng", path: "/ng", name: "nigeria", locale: 'en-GB'},
    // gn: {id: "gn", continent: "africa", code: "gn", path: "/gn", name: "guinea", locale: 'en-GB'},
    // gw: {id: "gw", continent: "africa", code: "gw", path: "/gw", name: "guinea-bissau", locale: 'en-GB'},
    // ke: {id: "ke", continent: "africa", code: "ke", path: "/ke", name: "kenya", locale: 'en-GB'},
    // re: {id: "re", continent: "africa", code: "re", path: "/re", name: "réunion", locale: 'en-GB'},
    // rw: {id: "rw", continent: "africa", code: "rw", path: "/rw", name: "rwanda", locale: 'en-GB'},
    // sh: {id: "sh", continent: "africa", code: "sh", path: "/sh", name: "saint helena", locale: 'en-GB'},
    // st: {id: "st", continent: "africa", code: "st", path: "/st", name: "são tomé and príncipe", locale: 'en-GB'},
    // sn: {id: "sn", continent: "africa", code: "sn", path: "/sn", name: "senegal", locale: 'en-GB'},
    // sc: {id: "sc", continent: "africa", code: "sc", path: "/sc", name: "seychelles", locale: 'en-GB'},
    // sl: {id: "sl", continent: "africa", code: "sl", path: "/sl", name: "sierra leone", locale: 'en-GB'},
    // so: {id: "so", continent: "africa", code: "so", path: "/so", name: "somalia", locale: 'en-GB'},
     za: {id: "za", continent: "africa", code: "za", path: "/za", name: "south africa", locale: 'en-GB', cn23: true, tva: 0},
    // ss: {id: "ss", continent: "africa", code: "ss", path: "/ss", name: "south sudan", locale: 'en-GB'},
    // sd: {id: "sd", continent: "africa", code: "sd", path: "/sd", name: "sudan", locale: 'en-GB'},
    // sz: {id: "sz", continent: "africa", code: "sz", path: "/sz", name: "swaziland", locale: 'en-GB'},
    // tz: {id: "tz", continent: "africa", code: "tz", path: "/tz", name: "tanzania", locale: 'en-GB'},
    // tg: {id: "tg", continent: "africa", code: "tg", path: "/tg", name: "togo", locale: 'en-GB'},
    // tn: {id: "tn", continent: "africa", code: "tn", path: "/tn", name: "tunisia", locale: 'en-GB'},
    // ug: {id: "ug", continent: "africa", code: "ug", path: "/ug", name: "uganda", locale: 'en-GB'},
    // eh: {id: "eh", continent: "africa", code: "eh", path: "/eh", name: "western sahara", locale: 'en-GB'},
    // ye: {id: "ye", continent: "africa", code: "ye", path: "/ye", name: "yemen", locale: 'en-GB'},
    // zm: {id: "zm", continent: "africa", code: "zm", path: "/zm", name: "zambia", locale: 'en-GB'},
    // zw: {id: "zw", continent: "africa", code: "zw", path: "/zw", name: "zimbabwe", locale: 'en-GB'},
    // as: {id: "as", continent: "oceania", code: "as", path: "/as", name: "american samoa", locale: 'en-GB'},
     au: {id: "au", continent: "oceania", code: "au", path: "/au", name: "australia", locale: 'en-GB', cn23: true, tva: 0},
    // ck: {id: "ck", continent: "oceania", code: "ck", path: "/ck", name: "cook islands", locale: 'en-GB'},
    // fj: {id: "fj", continent: "oceania", code: "fj", path: "/fj", name: "fiji", locale: 'en-GB'},
    // pf: {id: "pf", continent: "oceania", code: "pf", path: "/pf", name: "french polynesia", locale: 'en-GB'},
    // gu: {id: "gu", continent: "oceania", code: "gu", path: "/gu", name: "guam", locale: 'en-GB'},
    // ki: {id: "ki", continent: "oceania", code: "ki", path: "/ki", name: "kiribati", locale: 'en-GB'},
    // mh: {id: "mh", continent: "oceania", code: "mh", path: "/mh", name: "marshall islands", locale: 'en-GB'},
    // nr: {id: "nr", continent: "oceania", code: "nr", path: "/nr", name: "nauru", locale: 'en-GB'},
    // nc: {id: "nc", continent: "oceania", code: "nc", path: "/nc", name: "new caledonia", locale: 'en-GB'},
    // nz: {id: "nz", continent: "oceania", code: "nz", path: "/nz", name: "new zealand", locale: 'en-GB'},
    // nu: {id: "nu", continent: "oceania", code: "nu", path: "/nu", name: "niue", locale: 'en-GB'},
    // nf: {id: "nf", continent: "oceania", code: "nf", path: "/nf", name: "norfolk island", locale: 'en-GB'},
    // mp: {id: "mp", continent: "oceania", code: "mp", path: "/mp", name: "northern mariana islands", locale: 'en-GB'},
    // pw: {id: "pw", continent: "oceania", code: "pw", path: "/pw", name: "palau", locale: 'en-GB'},
    // pg: {id: "pg", continent: "oceania", code: "pg", path: "/pg", name: "papua new guinea", locale: 'en-GB'},
    // pn: {id: "pn", continent: "oceania", code: "pn", path: "/pn", name: "pitcairn islands", locale: 'en-GB'},
    // ws: {id: "ws", continent: "oceania", code: "ws", path: "/ws", name: "samoa", locale: 'en-GB'},
    // sb: {id: "sb", continent: "oceania", code: "sb", path: "/sb", name: "solomon islands", locale: 'en-GB'},
    // tk: {id: "tk", continent: "oceania", code: "tk", path: "/tk", name: "tokelau", locale: 'en-GB'},
    // to: {id: "to", continent: "oceania", code: "to", path: "/to", name: "tonga", locale: 'en-GB'},
    // tv: {id: "tv", continent: "oceania", code: "tv", path: "/tv", name: "tuvalu", locale: 'en-GB'},
    // vu: {id: "vu", continent: "oceania", code: "vu", path: "/vu", name: "vanuatu", locale: 'en-GB'},
    // wf: {id: "wf", continent: "oceania", code: "wf", path: "/wf", name: "wallis and futuna", locale: 'en-GB'},
    // ai: {id: "ai", continent: "america", code: "ai", path: "/ai", name: "anguilla", locale: 'en-GB'},
    // ag: {id: "ag", continent: "america", code: "ag", path: "/ag", name: "antigua and barbuda", locale: 'en-GB'},
     ar: {id: "ar", continent: "america", code: "ar", path: "/ar", name: "argentina", locale: 'es-ES', cn23: true, tva: 0},
    // aw: {id: "aw", continent: "america", code: "aw", path: "/aw", name: "aruba", locale: 'en-GB'},
    // bs: {id: "bs", continent: "america", code: "bs", path: "/bs", name: "bahamas", locale: 'en-GB'},
    // bb: {id: "bb", continent: "america", code: "bb", path: "/bb", name: "barbados", locale: 'en-GB'},
    // bz: {id: "bz", continent: "america", code: "bz", path: "/bz", name: "belize", locale: 'en-GB'},
    // bm: {id: "bm", continent: "america", code: "bm", path: "/bm", name: "bermuda", locale: 'en-GB'},
    // bo: {id: "bo", continent: "america", code: "bo", path: "/bo", name: "bolivia", locale: 'en-GB'},
     br: {id: "br", continent: "america", code: "br", path: "/br", name: "brazil", locale: "en-GB", cn23: true, tva: 0},
    // vg: {id: "vg", continent: "america", code: "vg", path: "/vg", name: "british virgin islands", locale: 'en-GB'},
     ca: {id: "ca", continent: "america", code: "ca", path: "/ca", name: "canada", cn23: true, tva: 0,
         locale: {
            fr: {message: "Choisissez votre langue", label: 'Français'},
            en: {message: "Choose your language", label: 'English'},
         },
     },
    // bq: {id: "bq", continent: "america", code: "bq", path: "/bq", name: "caribbean netherlands", locale: 'en-GB'},
    // ky: {id: "ky", continent: "america", code: "ky", path: "/ky", name: "cayman islands", locale: 'en-GB'},
    // cl: {id: "cl", continent: "america", code: "cl", path: "/cl", name: "chile", locale: 'en-GB'},
    // co: {id: "co", continent: "america", code: "co", path: "/co", name: "colombia", locale: 'en-GB'},
    // cr: {id: "cr", continent: "america", code: "cr", path: "/cr", name: "costa rica", locale: 'en-GB'},
    // cu: {id: "cu", continent: "america", code: "cu", path: "/cu", name: "cuba", locale: 'en-GB'},
    // cw: {id: "cw", continent: "america", code: "cw", path: "/cw", name: "curaçao", locale: 'en-GB'},
    // dm: {id: "dm", continent: "america", code: "dm", path: "/dm", name: "dominica", locale: 'en-GB'},
    // do: {id: "do", continent: "america", code: "do", path: "/do", name: "dominican republic", locale: 'en-GB'},
    // ec: {id: "ec", continent: "america", code: "ec", path: "/ec", name: "ecuador", locale: 'en-GB'},
    // sv: {id: "sv", continent: "america", code: "sv", path: "/sv", name: "el salvador", locale: 'en-GB'},
    // fk: {id: "fk", continent: "america", code: "fk", path: "/fk", name: "falkland islands", locale: 'en-GB'},
    // gf: {id: "gf", continent: "america", code: "gf", path: "/gf", name: "french guiana", locale: 'en-GB'},
    // gl: {id: "gl", continent: "america", code: "gl", path: "/gl", name: "greenland", locale: 'en-GB'},
    // gd: {id: "gd", continent: "america", code: "gd", path: "/gd", name: "grenada", locale: 'en-GB'},
    // gp: {id: "gp", continent: "america", code: "gp", path: "/gp", name: "guadeloupe", locale: 'en-GB'},
    // gy: {id: "gy", continent: "america", code: "gy", path: "/gy", name: "guyana", locale: 'en-GB'},
    // gt: {id: "gt", continent: "america", code: "gt", path: "/gt", name: "guatemala", locale: 'en-GB'},
    // ht: {id: "ht", continent: "america", code: "ht", path: "/ht", name: "haiti", locale: 'en-GB'},
    // jm: {id: "jm", continent: "america", code: "jm", path: "/jm", name: "jamaica", locale: 'en-GB'},
    // mq: {id: "mq", continent: "america", code: "mq", path: "/mq", name: "martinique", locale: 'en-GB'},
    // hn: {id: "hn", continent: "america", code: "hn", path: "/hn", name: "honduras", locale: 'en-GB'},
    // mx: {id: "mx", continent: "america", code: "mx", path: "/mx", name: "mexico", locale: 'en-GB'},
    // ms: {id: "ms", continent: "america", code: "ms", path: "/ms", name: "montserrat", locale: 'en-GB'},
    // pa: {id: "pa", continent: "america", code: "pa", path: "/pa", name: "panama", locale: 'en-GB'},
    // ni: {id: "ni", continent: "america", code: "ni", path: "/ni", name: "nicaragua", locale: 'en-GB'},
    // py: {id: "py", continent: "america", code: "py", path: "/py", name: "paraguay", locale: 'en-GB'},
    // pe: {id: "pe", continent: "america", code: "pe", path: "/pe", name: "peru", locale: 'en-GB'},
    // pr: {id: "pr", continent: "america", code: "pr", path: "/pr", name: "puerto rico", locale: 'en-GB'},
    // bl: {id: "bl", continent: "america", code: "bl", path: "/bl", name: "saint barthélemy", locale: 'en-GB'},
    // kn: {id: "kn", continent: "america", code: "kn", path: "/kn", name: "saint kitts and nevis", locale: 'en-GB'},
    // lc: {id: "lc", continent: "america", code: "lc", path: "/lc", name: "saint lucia", locale: 'en-GB'},
    // mf: {id: "mf", continent: "america", code: "mf", path: "/mf", name: "saint martin", locale: 'en-GB'},
    // pm: {id: "pm", continent: "america", code: "pm", path: "/pm", name: "saint pierre and miquelon", locale: 'en-GB'},
    // sx: {id: "sx", continent: "america", code: "sx", path: "/sx", name: "sint maarten", locale: 'en-GB'},
    // gs: {id: "gs", continent: "america", code: "gs", path: "/gs", name: "south georgia &amp; south sandwich islands", locale: 'en-GB'},
    // uy: {id: "uy", continent: "america", code: "uy", path: "/uy", name: "uruguay", locale: 'en-GB'},
    us: {id: "us", continent: "america", code: "us", path: "", name: "united states", locale: 'en-GB', cn23: true, tva: 0},
    // um: {id: "um", continent: "america", code: "um", path: "/um", name: "u.s. outlying islands", locale: 'en-GB'},
    // vi: {id: "vi", continent: "america", code: "vi", path: "/vi", name: "u.s. virgin islands", locale: 'en-GB'},
    // vc: {id: "vc", continent: "america", code: "vc", path: "/vc", name: "st. vincent &amp; grenadines", locale: 'en-GB'},
    // sr: {id: "sr", continent: "america", code: "sr", path: "/sr", name: "suriname", locale: 'en-GB'},
    // tt: {id: "tt", continent: "america", code: "tt", path: "/tt", name: "trinidad and tobago", locale: 'en-GB'},
    // tc: {id: "tc", continent: "america", code: "tc", path: "/tc", name: "turks and caicos islands", locale: 'en-GB'},
    // //bv: {id: "bv", continent: "antarctica", code: "bv", path: "/bv", name: "bouvet island", locale: 'en-GB'},
    // ve: {id: "ve", continent: "america", code: "ve", path: "/ve", name: "venezuela", locale: 'en-GB'},

    //aq: {id: "aq", continent: "antarctica", code: "aq", path: "/aq", name: "antarctica", locale: 'en-GB'},
    //tf: {id: "tf", continent: "antarctica", code: "tf", path: "/tf", name: "french southern territories", locale: 'en-GB'},
    //hm: {id: "hm", continent: "antarctica", code: "hm", path: "/hm", name: "heard &amp; mcdonald islands", locale: 'en-GB'},
};

export const continents = [
    'europe',
    'america',
    'asia',
    'africa',
    'oceania',
    //'antarctica'
];

export const DEFAULT_COUNTRY =  countries.us;

