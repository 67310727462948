import React from "react"
import {injectIntl} from "react-intl";
import locationApi from "../../Service/LocationApi";
import {withLocalization} from "../../Providers/LocalizationProvider";
import Button from "react-bootstrap/Button";
import {Redirect, withRouter} from "react-router-dom"
import Dropdown from "react-bootstrap/Dropdown";
import Image from "react-bootstrap/Image";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import IntlText from "../Intl/Text";
import {countries, DEFAULT_COUNTRY} from "../../countries";


const excludedPath = [
    '/select-country'
];

class CountrySelectorRibbon extends React.Component {
	static displayName = 'CountrySelectorRibbon';
    constructor(props) {
        super(props);
        this.state = {
            show: true,
            detectedCountry: false,
            countryIsValid: false,
            routeSelection: false,
            redirect: false,
            selectedItem: false
        }

    }

    async getCountryOfCurrentLocation() {
        const result = await locationApi.getLocationData();
        if (!result.status === 'success' || !result.countryCode) {
            return undefined;
        }
        const countryId = result.countryCode.toLowerCase();
        return countries[countryId];
    }

    async componentDidMount() {
        if ((this.props.localization.country.id === DEFAULT_COUNTRY.id) && (excludedPath.indexOf(this.props.location.pathname) === -1)) {
            const detectedCountry = await this.getCountryOfCurrentLocation();
            if (detectedCountry && (detectedCountry.id !== DEFAULT_COUNTRY.id)) {
                this.setState({detectedCountry: detectedCountry})
            }
        }
    }

    handleClick = (event) => {
        if (this.state.selectedItem === 'other') {
            this.setState({redirect: this.props.localization.getPageUrl('countries') + '?p=' + this.props.routeConfig.path});
        } else {
            //const path = this.state.detectedCountry.path !== '/' ? this.state.detectedCountry.path : ''
            //this.setState({redirect: this.state.detectedCountry.path + '/redirect?p=' + this.props.routeConfig.path});

            document.location = this.state.detectedCountry.path + '/redirect?p=' + this.props.routeConfig.path;
        }
    };

    getSelectedItem() {
        if (this.state.selectedItem === 'other') {
            return this.getOtherItemContent();
        } else {
            return this.getCountryItemContent();
        }
    }

    getCountryItemContent() {
        return (
            <div className={'country-selector-ribbon-dropdown-item'}>
                <Image src={`/images/flags/${this.state.detectedCountry.code}_32.png`} width={'20px'} height={'20px'}
                       className={'mr-2'}/>
                {this.props.intl.formatMessage({id: 'country.' + this.state.detectedCountry.code})}
            </div>
        );
    }

    getOtherItemContent() {
        return (
            <div className={'country-selector-ribbon-dropdown-item'}>
                {this.props.intl.formatMessage({id: `country.other.${this.state.detectedCountry.code}`})}
            </div>
        );
    }

    getDropDown() {
        return (
            <Dropdown onSelect={this.handleOnSelect} className={'country-selector-ribbon-dropdown'}>
                <Dropdown.Toggle variant="secondary" id="country-selector-ribbon"
                                 className={'country-selector-ribbon-dropdown'}>
                    {this.getSelectedItem()}
                </Dropdown.Toggle>

                <Dropdown.Menu className={'country-selector-ribbon-dropdown-menu'}>
                    <Dropdown.Item eventKey={"country"}>{this.getCountryItemContent()}</Dropdown.Item>
                    <Dropdown.Item eventKey={"other"}>{this.getOtherItemContent()}</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    handleOnSelect = (event) => {
        this.setState({selectedItem: event});
    }

    handleOnClose = () =>{
        this.setState({show: false});
    }
    render() {

        if (this.state.redirect) {
            return (<Redirect to={this.state.redirect}/>)
        }
        if (this.state.detectedCountry && this.state.show) {
            const {code} = this.state.detectedCountry;
            return (
                <div className={"country-selector-ribbon-container"}>
                    <div className="country-ribbon">
                        <div className="country-selector-ribbon-text">
                            <IntlText messageId={`country.selector.ribbon.message.${code}`}/>
                        </div>
                        <div className="country-selector-ribbon-controls">
                            {this.getDropDown()}
                            <Button onClick={this.handleClick}>
                                <IntlText messageId={`common.continue`}/>
                            </Button>
                        </div>
                    </div>
                    <div className="country-selector-ribbon-close" role="button" onClick={this.handleOnClose}>
                        <FontAwesomeIcon icon={faTimes}/>
                    </div>
                </div>
            )
        }
        return null;
    }

}

export default injectIntl(withLocalization(withRouter(CountrySelectorRibbon)));
