import Modal from "react-bootstrap/Modal";
import React from "react";
import IntlText from "../Intl/Text";


function Popup (props) {

return (
        <Modal
            show={props.show}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={props.onHide}
            backdrop="static"
        >
            <Modal.Header closeButton >
                <Modal.Title id="contained-modal-title-vcenter">
                    <IntlText messageId={props.header}/>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {React.Children.toArray(props.children)}
            </Modal.Body>
        </Modal>
    );

}
Popup.displayName = 'Popup';
export default Popup;

