/**
 * From ReactGA Community Wiki Page https://github.com/react-ga/react-ga/wiki/React-Router-v4-withTracker
 */

import React, {Component} from 'react';
import ReactGA from "react-ga4";
import {withGA} from "./ReactGAProvider";

export default function withTracker(WrappedComponent, options = {}) {
    const trackPage = (page, ga) => {
        ReactGA.set({
            page,
            ...options
        });
        ga.pageview(page);
    };

    const HOC = class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                currentPage: props.location.pathname,
            }
        }
        componentDidMount() {
            const page = this.props.location.pathname;
            trackPage(page, this.props.ga);
        }
        static getDerivedStateFromProps(props, state) {
            if (state.currentPage !== props.location.pathname) {
                trackPage(props.location.pathname, props.ga);
            }
            return {currentPage: props.location.pathname};
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    };

    return withGA(HOC);
}
