import React from "react";
import {injectIntl} from "react-intl";
import {withRouter} from "react-router-dom";
import {withLogin} from "../../Providers/LoginProvider";
import {withLocalization} from "../../Providers/LocalizationProvider";
import IntlText from "../Intl/Text";
import {IoIosLogOut} from "react-icons/io";
import AppLink from "../AppLink";
import ClassNames from "classnames"
import {makeSelectAccount} from "./selectors";
import {makeSelectAuth} from "../../Providers/AuthProvider/selectors";
import {connect} from "react-redux";
import {withGA} from "../../Providers/ReactGAProvider";

class Account extends React.Component {
	static displayName = 'Account';
render() {

        const {getPageUrl, getUrl} = this.props.localization;
        const classNames = ClassNames('account-menu', this.props.className);

        if (this.props.login.isLoggedIn() && !this.props.login.showLogin) {

            return (
                <div className={classNames}>
                    <AppLink page={"accountOrders"}>
                        <IntlText messageId={'menu.account'}/>
                    </AppLink>
                    {this.props.noDisconnectIcon ? null :
                        <IoIosLogOut className={'icon-disconnect'}
                                     onClick={() => {
                                         this.props.ga.event('Account', 'logout', 'logout from the toolbar');
                                         this.props.login.onLogout('/')
                                     }}
                                     title={this.props.intl.formatMessage({id: 'common.diconnect'})}/>
                    }
                </div>
            )
        } else {
            return (
                <div className={classNames}>
                    <button onClick={() => this.props.login.onShowLogin({
                        redirectOnSuccess: getUrl(getPageUrl("accountOrders"))
                    })}>
                        <IntlText messageId={'menu.account'}/>
                    </button>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    account: makeSelectAccount(state),
    auth: makeSelectAuth(state),
    //locale: makeSelectLocale(state)
});

export default connect(mapStateToProps)(withGA(withLogin(withLocalization(withLogin(withRouter(injectIntl(Account)))))));
