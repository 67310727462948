// Production environment


const environment = {
    environment: 'production',
    //API
    tx_api_base_path: 'https://api.toxseek.com',
    stripe_public_key: 'pk_live_bdkVMhudpVJmtFwPREbT3k6X0025Og0Pmw',
    ga_id: 'G-RRKJHHVNHS', //'UA-140388346-4',
    cdn: 'https://cdn.toxseek.com',
    paymentGateway: [
        {label: "Stripe", logo: "/images/payment/toxseekpay.png", id: "stripe"}
    ]
};

export default environment;
