class LocationApi {
	static displayName = 'LocationApi';
constructor() {
        this.serviceUrl = 'https://pro.ip-api.com/json?key=icXrgknUwMjMB5c' //?fields=status,message,country,countryCode,timezone';
        this.locationData = undefined;
    }

    /*const result = {
            "as": "AS3215 Orange S.A.",
            "city": "Clichy-sous-Bois",
            "country": "France",
            "countryCode": "FR",
            "isp": "Orange",
            "lat": 48.9109,
            "lon": 2.54615,
            "org": "",
            "query": "193.248.142.247",
            "region": "IDF",
            "regionName": "Île-de-France",
            "status": "success",
            "timezone": "Europe/Paris",
            "zip": "93390"
        };*/
    async getLocationData() {


        if (! this.locationData) {
            const request = {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Origin': '*'
                }
            };
            const result = await fetch(this.serviceUrl, request);
            this.locationData = await result.json()
        }
        /*if(false) {
            this.locationData = {
                as: "AS12322 Free SAS",
                city: "San Fransisco",
                country: "United State",
                countryCode: "US",
                isp: "Free SAS",
                lat: 37.774929,
                lon: -122.419416,
                org: "Free",
                query: "88.124.221.169",
                region: "IDF",
                regionName: "Île-de-France",
                status: "success",
                timezone: "Europe/Paris",
                zip: "95650"
            }
        }*/
        return this.locationData;
    }
}

export default new LocationApi();
