import {createStore} from "redux";
import {persistReducer, persistStore} from 'redux-persist'
import createReducer from "./reducers"
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'root',
    storage,
};


const persistedReducer = persistReducer(persistConfig, createReducer());


export default () => {
    let store = createStore(persistedReducer);
    let persistor = persistStore(store);
    return { store, persistor };
}
