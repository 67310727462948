import React from "react"
import { useContext} from "react"
import toxseekApiV1 from "../../Service/ToxseekApi";
import {withRecaptcha} from "../RecaptchaProvider";

const ApiContext = new React.createContext({
    v1: undefined
});

class ApiProvider extends React.Component {
	static displayName = 'ApiProvider';
render() {

        return (
            <ApiContext.Provider value={{
                v1: new toxseekApiV1(this.props.recaptcha),
            }}>
                {this.props.children}
            </ApiContext.Provider>
        )
    }
}

export default withRecaptcha(ApiProvider);

export function withApi(WrappedElement) {

    return class extends React.Component {

        render() {
            return (
                <ApiContext.Consumer>
                    {(context) => (
                        <WrappedElement api={context} {...this.props}/>
                    )}
                </ApiContext.Consumer>
            )
        }
    }
}

export function useToxseekApi() {
    return useContext(ApiContext);
}
