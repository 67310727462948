import environment from "../../environment";
import crypt from "../../utils/crypt";
import TokenExpiredError from './TokenExpiredError';
import {store} from '../../components/Bootstrap'
import {selectAuth} from "../../Providers/AuthProvider/selectors";
import {authLogin} from "../../Providers/AuthProvider/actions";
import axios from "axios"

class ToxseekApiV1
{
    constructor(recaptcha) {
        this.recaptcha = recaptcha;
        this.apiBasePath = environment.tx_api_base_path;
        this.apiVersion = 1;
    }

    async getRecaptchaToken(action) {
        const token = await this.recaptcha.execute(action);
        return {
            recaptchaToken: token,
            recaptchaAction: action,
        }
    }

    async placeOrder(orderData) {
        let body = {
            orderData: orderData,
        };

        if (orderData.customerId) {
            return await this._postApiData('account/place-order', body);
        } else {
            return await this._postData('place-order', body);
        }
    }

    async productActivation(activationData) {

        let body = {
            activationData: activationData,
        };

        if (activationData.customerId) {
            return await this._postApiData('account/product-activation', body);
        } else {
            return await this._postData('product-activation', body);
        }
    }

    async getTransactionCheck(reference, type) {
        return await this._postData('transaction-check',{reference, type});
    }

    async getEmailCheck(email, recaptcha) {
        return await this._postData('email-check',{email: email});
    }

    async contact(form) {
        return await this._postData('contact', form);
    }

    async getCheckoutData(orderCode, paymentMethod) {
        return await this._postData('checkout-data',{
            orderCode,
            paymentMethod,
            basePath: window.location.origin
        });
    }
    async getCapturePayment(transactionReference, basePath) {
        return await this._postApiData('capture-payment',{
            transactionReference,
            basePath
        })
    }

    async getPrescriberCode(code, products, email, country) {
        return await this._postData('prescriber-code',{code, products, email, country});
    }

    async getCountries(recaptcha) {
        return await this._postData('countries', {});
    }

    async getOrderData(countryCode) {
        return await this._postData('order-data',{countryCode: countryCode}, false);
    }

    async changePassword(password, sLinkToken) {
        return await this._postData('password',{
            auth: sLinkToken,
            password: password
        }, false);
    }

    async createAccount(accountData) {
        return await this._postData('create-account',accountData);
    }

    async recoverPassword(email, locale, basePath) {
        return await this._postData('recover',{
            email: email,
            locale: locale,
            basePath: basePath
        },false)
    }

    async resendSlink(slinkToken) {
        return await this._postData('resend-slink', {
            auth: slinkToken
        }, false)
    }

    async getSecuredLink(slinkToken) {
        return await this._postData('slink', {
            auth: slinkToken
        }, false)
    }

    async getOrders() {
        return await this._getApiData('orders')
    }

    async getResults(type) {
        return await this._postApiData('results', {
            type
        })
    }

    async getOrderDetail(orderCode) {

        return await this._postApiData('order-detail', {orderCode});
    }

    async getProfile() {
        return await this._getApiData('customer')
    }

    async setProfile(values) {
        return await this._postApiData('customer', values);
    }

    async getToken(username, password) {

        const body = {
            grant_type: 'password',
            scope: 'user.all',
            username: username,
            password: password,
            client_id: 'client_user_api',
            client_secret: 'Fldj59qs:dkf$ejbsdgs-',
        };

        return await this._fetchData('/oauth', body, 'POST', false, true, true);
    }

    async downloadFile_old(file_id, file_name) {

        const auth = selectAuth(store.getState());
        if (! auth || !auth.token || !auth.token.access_token) {
            throw new Error('api.error.not-authentified');
        }

        const request = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/encrypted-json',
                'Origin': '*',
                'Authorization': 'Bearer ' + auth.token.access_token
            },
            body: crypt.encrypt(JSON.stringify({file_id}))
        };

        const path = '/api/v' + this.apiVersion + '/download';

        let response = await fetch(this.apiBasePath + path, request);

        if (response.ok) {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${file_name}`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }
    }

    async downloadFile(file_id, file_name) {
        const auth = selectAuth(store.getState());
        if (! auth || !auth.token || !auth.token.access_token) {
            throw new Error('api.error.not-authentified');
        }

        const request = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/encrypted-json',
                'Authorization': 'Bearer ' + auth.token.access_token
            },
            responseType: 'arraybuffer',
            data: crypt.encrypt(JSON.stringify({file_id}))
        };

        const path = '/api/v' + this.apiVersion + '/download';

        let response = await axios(this.apiBasePath + path, request);

        if (response.status === 200) {
            const blob = response.data
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${file_name}`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } else {
            console.log(response);
        }
    }

    async _fetchData_old(path, body, method, token, crypted = true, raw = false) {
        const request = {
            method: method,
            headers: {
                'Content-Type': crypted ? 'application/encrypted-json' : 'application/json',
                'Accept': 'application/json',
                'Origin': '*'
            }
        };

        if ((method === 'POST') && body) {
            request['body'] = crypted ? crypt.encrypt(JSON.stringify(body)) : JSON.stringify(body);
        }

        if (token) {
            request.headers['Authorization'] = 'Bearer ' + token;
        }

        const xdebug = environment.environment !== 'production' ? '?XDEBUG_SESSION_START=PHPSTORM' : '';
        let response = await fetch(this.apiBasePath + path + xdebug, request);
        if (response.ok) {

            const result = await response.json();
            if (raw) {
                return result;
            }
            if (result.success) {
                return result.data;
            } else {
                if (result.message) {
                    throw new Error(result.message);
                }
                throw new Error(result.errorCode);
            }
        } else {
            let result;
            let message = 'api.error.resourceUnavailable';
            try {
                result = await response.json();
            } catch (e) {
                console.error(e.message);
            }

            if (result && result.detail) {
                // Show to the user a généric error message
                throw new Error(result.detail);
            }

            switch (response.status) {
                case 401:
                    if (token) {
                        throw new TokenExpiredError();
                    }
                    message = 'api.error.authenticationFailed';
                    break;
                case 400:
                    message = 'api.error.authenticationFailed';
                    break;
                case response.status >= 500:
                default:
                    message = 'api.error.serveurError';
                    break;
            }
            throw new Error(message);
        }
    }

    //Using axios
    async _fetchData(path, body, method, token, crypted = true, raw = false) {
        const request = {
            method: method,
            headers: {
                'Content-Type': crypted ? 'application/encrypted-json' : 'application/json',
                'Accept': 'application/json',
            }
        };

        if ((method === 'POST') && body) {
            request['data'] = crypted ? crypt.encrypt(JSON.stringify(body)) : JSON.stringify(body);
        }

        if (token) {
            request.headers['Authorization'] = 'Bearer ' + token;
        }

        const xdebug = environment.environment !== 'production' ? '?XDEBUG_SESSION_START=PHPSTORM' : '';

        let response;

        try{
            response = await axios(this.apiBasePath + path + xdebug, request);
        } catch (e) {
            response = e.response
        }
        //response.status = 503;

        if (response && response.status === 200) {

            const result = response.data;
            if (raw) {
                return result;
            }
            if (result.success) {
                return result.data;
            } else {
                if (result.message) {
                    throw new Error(result.message);
                }
                throw new Error(result.errorCode);
            }
        } else {
            // @translationPattern: (api.error.serveurError|api.error.authenticationFailed|api.error.resourceUnavailable|api.error.maintenanceInProgress)(|.title|.body)
            if (! response) {
                throw new Error('api.error.maintenanceInProgress');
            }
            
            let result;
            let message = 'api.error.resourceUnavailable';
            result = response.data;

            if (result && result.detail) {
                // Show to the user a généric error message
                throw new Error(result.detail);
            }

            switch (response.status) {
                case 503:
                    message = 'api.error.maintenanceInProgress';
                    break;
                case 401:
                    if (token) {
                        throw new TokenExpiredError();
                    }
                    message = 'api.error.authenticationFailed';
                    break;
                case 400:
                    message = 'api.error.authenticationFailed';
                    break;
                case response.status >= 500:
                default:
                    message = 'api.error.serveurError';
                    break;
            }
            throw new Error(message);
        }
    }

    async _getApiData(resource) {
        return await this._fetchApiData(resource, 'GET');
    }

    async _postApiData(resource, body) {
        return await this._fetchApiData(resource, 'POST', body);
    }

    async _postData(resource, body, injectRecaptcha = true) {
        let data = body;
        if (injectRecaptcha) {
            data = {
                ...body,
                ...await this.getRecaptchaToken(resource.replace('-', '_'))
            };
        }
        return await this._fetchData('/api/v' + this.apiVersion + '/' + resource, data, 'POST');
    }

    async _fetchApiData(resource, method, body = null, count = 0) {

        const auth = selectAuth(store.getState());
        if (! auth || !auth.token || !auth.token.access_token) {
            throw new Error('api.error.not-authentified');
        }

        try {
            return await this._fetchData('/api/v' + this.apiVersion + '/' +  resource, body, method, auth.token.access_token);
        } catch (e) {
            if (e instanceof TokenExpiredError && (count === 0)) {
                await this._refreshToken();
                return await this._fetchApiData( resource, method, body, count++);
            }
            throw e;
        }

    }

    async _refreshToken() {

        const auth = selectAuth(store.getState());
        if (! auth || !auth.token || !auth.token.access_token) {
            throw new Error('api.error.not-authentified');
        }

        const body = {
            grant_type: 'refresh_token',
            scope: 'user.all',
            refresh_token: auth.token.refresh_token,
            client_id: 'client_user_api',
            client_secret: 'Fldj59qs:dkf$ejbsdgs-',
        };


            const newToken = await this._fetchData('/oauth', body, 'POST', false, true, true);
            await store.dispatch(authLogin(newToken));

    }
}


export default ToxseekApiV1;
