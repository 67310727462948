/*
 *
 * AuthProvider constants
 *
 */


//ACTIONS
export const SET_PRODUCT_QUANTITY = 'order/SET_PRODUCT_QUANTITY';
export const SET_COUNTRY = 'order/SET_COUNTRY';
export const SET_CUSTOMER_ID = 'order/SET_CUSTOMER_ID';
export const SET_PAYMENT_GATEWAY_ID = 'order/SET_PAYMENT_GATEWAY_ID';
export const RESET_ORDER = 'order/RESET_ORDER';
export const SAVE_ORDER = 'order/SAVE_ORDER';

