import React from "react"
import {injectIntl} from "react-intl";
import {withLocalization} from "../../Providers/LocalizationProvider";
import IntlText from "../Intl/Text";

class InformationRibbon extends React.Component {
	static displayName = 'CountrySelectorRibbon';
    constructor(props) {
        super(props);
        this.state = {}

    }
    componentDidMount() {
        const message = (this.props.intl.formatMessage({id: 'information-ribbon.text'})).trim();
        this.setState({show: message.length > 0 && (message !== 'information-ribbon.text')});
    }

    render() {
        if (!this.state.show) {
            return null;
        }
        return (
            <div className={"information-ribbon-container"}>
                <div className="information-ribbon">
                    <div className="information-ribbon-content">
                        <IntlText messageId={"information-ribbon.text"} values={{country: this.props.localization.country.name}}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(withLocalization(InformationRibbon));
