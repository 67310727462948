import Account from "./components/Account";
import React from "react";

export const DEFAULT_DEPARTMENT = {
    name: "default",
    homePage: 'home',
    class: '',
    logo: "/images/logo/home/logo-ts-{locale}.png",
    menus: {
        main: [
            {messageId: 'menu.analyses', page: 'analyseIntegral'},
            {messageId: 'menu.why', page: 'why'},
            {messageId: 'menu.press', page: 'press'},
            {messageId: 'menu.about', page: 'about'},
            {messageId: 'menu.contact', page: 'contactPress'},
        ],
        toolbar: [
            {messageId: 'menu.activation', page: 'activation', className: 'activation'},
            {component: (props) => <Account {...props}/>}
        ],
    },
    pages: {
        countries: 'countries',
    }
};

export const departments = {
    default: DEFAULT_DEPARTMENT,
    veterinary: {
        homePage: 'veterinaryHome',
        name: "veterinary",
        class: 'veterinary',
        logo: "/veterinary/images/ts-veterinaire-logo-{locale}.png",
        menus: {
            main: [
                {messageId: 'menu.veterinary.what', page: 'veterinaryWhat'},
                {messageId: 'menu.veterinary.study-case', page: 'veterinaryStudyCase'},
                {messageId: 'menu.contact', page: 'contactPress'},
            ],
            toolbar: [],
        },
        pages: {
            countries: 'veterinaryCountries',
        }
    },

}
