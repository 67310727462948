/**
 * Combine all reducers in this file and export the combined reducers.
 */

import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';

import history from './utils/history';
import globalReducer from './components/App/reducer';
import languageProviderReducer from './Providers/LanguageProvider/reducer';
import authProviderReducer from "./Providers/AuthProvider/reducer";
import accountReducer from "./components/Account/reducer";
import orderReducer from "./components/Form/OrderForm/reducer";
import activationReducer from "./components/Form/ActivationForm/reducer";

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
    return combineReducers({
        global: globalReducer,
        language: languageProviderReducer,
        auth: authProviderReducer,
        account: accountReducer,
        order: orderReducer,
        activation: activationReducer,
        router: connectRouter(history),
        ...injectedReducers,
    });
}
