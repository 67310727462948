import React from "react"
import IntlText from "./Intl/Text";
import {withLocalization} from "../Providers/LocalizationProvider";
import AppLink from "./AppLink"
import {connect} from "react-redux";
import {makeSelectAuth} from "../Providers/AuthProvider/selectors";
import {withLogin} from "../Providers/LoginProvider";
//import Burger from 'react-css-burger';
import CountrySelectorLink from "./CountrySelectorLink";
import {withGA} from "../Providers/ReactGAProvider";
import {GiHamburgerMenu} from "react-icons/gi";
import {withDepartment} from "../Providers/DepartmentProvider";

class Menu extends React.Component {
    static displayName = 'Menu';

    constructor(props) {
        super(props);
        this.state = {
            open: false,
        }
    }

    buildMenuItem = (menuConfig, index) => {
        const component = menuConfig.hasOwnProperty('component') ? menuConfig.component(menuConfig) :
        (
            <AppLink {...menuConfig}>
                <IntlText messageId={menuConfig.messageId}/>
            </AppLink>
        );

        return <li key={index}>
            {component}
        </li>
    };

    handleClick = () => {
        this.setState({open: false});
    }


    render() {
        const {main, toolbar} = this.props.department.menus;

        return (
            <nav id="site-menu">
                <GiHamburgerMenu
                    onClick={() => this.setState({open: !this.state.open})}
                    //active={this.state.open}
                    burger="collapse"
                    color="black"
                    //hoverOpacity={1}
                    size={30}
                />
                <div className={"liste-menus " + (this.state.open ? "opened" : "")} onClick={this.handleClick}>
                    <ul id="menu1">
                        {main.map(this.buildMenuItem)}
                    </ul>
                    <ul id="menu2">
                        {toolbar.map(this.buildMenuItem)}
                    </ul>
                    <ul id="menu4">
                        <CountrySelectorLink routeConfig={this.props.routeConfig}/>
                    </ul>
                </div>
            </nav>
        )
    }
}


export default connect(makeSelectAuth)(withGA(withLocalization(withLogin(withDepartment(Menu)))));
