import CountrySelectorRibbon from "../components/CountrySelectorRibbon";
import Header from "../components/Header";
import React, {Suspense} from "react";
import SplashScreen from "../components/SplashScreen";
import CookiesRibbon from "../components/CookiesRibbon";
import Footer from "../components/Footer";
import DepartmentProvider from "../Providers/DepartmentProvider";


class Layout extends React.Component
{
    render() {

        const BodyComponent = this.props.component;
        return (
            <DepartmentProvider department={this.props.department}>
                <div className={this.props.department.class}>
                    <CountrySelectorRibbon routeConfig={this.props.routeConfig}/>
                    <Header routeConfig={this.props.routeConfig}/>
                    <main id="main-content" className={"main " + this.props.department.class}>
                        <Suspense fallback={<SplashScreen />}>
                            <CookiesRibbon />
                            <BodyComponent {...this.props}/>
                        </Suspense>
                    </main>
                    <Footer/>
                </div>
            </DepartmentProvider>
        )
    }
}


export default Layout;
