import React from 'react';
import ReactDOM from "react-dom"
import Spinner from "react-bootstrap/Spinner";
import {injectIntl} from "react-intl";


function Loading(props) {
let style={
        display: props.active ? 'flex' : 'none',
    };

    return (
        //<Fade>
            <div className={'loading-wrapper'} style={style}>
                <div style={{
                    flexDirection: 'column',
                    position: 'relative',
                    width: 'auto',
                }}>
                    <Spinner animation="border" variant="white" />
                    <p><span style={{fontSize: '1.5em', color: 'white'}}>{props.intl.formatMessage({id: (props.message || 'common.loading')})}</span></p>
                </div>
            </div>
        //</Fade>
    );
}
Loading.displayName = 'Loading';

export default injectIntl((props) => { return ReactDOM.createPortal(<Loading {...props}/>, document.querySelector("#loading"))});
