/*
 *
 * LanguageProvider reducer
 *
 */

import produce from 'immer';


import {
    RESET_ORDER,
    SAVE_ORDER,
    SET_COUNTRY,
    SET_CUSTOMER_ID,
    SET_PAYMENT_GATEWAY_ID,
    SET_PRODUCT_QUANTITY,
} from './constants';


export const initialState = {
    products: {
        organic: 0,
        integral: 0,
        metal: 0,
    },
    prescriber: '',
    civility: "1",
    firstName: '',
    lastName: '',
    phone: '',
    phoneValidated: '',
    email: '',
    country: '',
    address: {
        shipping: {
            address_1: '',
            address_2: '',
            zip: '',
            state: '',
            city: '',
        },
        billing: {
            address_1: '',
            address_2: '',
            zip: '',
            state: '',
            city: '',
        }
    },
    billingAddressSame: true,
    gsc: false,
    companySku: 'serfi',
    //paymentGatewayId: ''
};

/* eslint-disable default-case, no-param-reassign */
const orderReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case SET_PRODUCT_QUANTITY:
                draft.products[action.productId] = action.quantity;
                break;
            case SET_COUNTRY:
                draft.country = action.country;
                break;
            case SET_CUSTOMER_ID:
                draft.customerId = action.customerId;
                break;
            case SET_PAYMENT_GATEWAY_ID:
                draft.paymentGatewayId = action.paymentGatewayId;
                break;
            case RESET_ORDER:
                draft = Object.assign(draft, initialState);
                break;
            case SAVE_ORDER:
                draft.products = action.order.products;
                draft.address.billing = action.order.address.billing;
                draft.address.shipping = action.order.address.shipping;
                draft.billingAddressSame = action.order.billingAddressSame;
                draft.country = action.order.country;
                break;
        }
    });

export default orderReducer;
