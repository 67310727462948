/*
 *
 * LanguageProvider reducer
 *
 */

import produce from 'immer';

import {RESET_ACTIVATION, SET_CODE, SET_DATE, SET_MINOR} from './constants';

export const initialState = {
    code: '',
    minor: false,
    samplingDate: new Date(),
};

/* eslint-disable default-case, no-param-reassign */
const activationReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case SET_CODE:
                draft.code = action.code;
                break;
            case SET_MINOR:
                draft.minor = action.minor;
                break;
            case SET_DATE:
                draft.samplingDate = action.samplingDate;
                break;
            case RESET_ACTIVATION:
                draft = Object.assign(draft, initialState);
                break;
        }
    });

export default activationReducer;
