import React from "react"
import PopupLogin from "../../components/Popup/Login";
import {authLogout} from "../AuthProvider/actions";
import {setProfile} from "../../components/Account/actions";
import {makeSelectAccount} from "../../components/Account/selectors";
import {makeSelectAuth} from "../AuthProvider/selectors";
import {connect} from "react-redux";
import {withLocalization} from "../LocalizationProvider";

const LoginContext = React.createContext({
    showLogin: false,
    redirectOnSuccess: '/',
    onShowLogin: (options = {}) => {console.log('LoginContext was not correctly initialized.')},
    onHideLogin: () => {console.log('LoginContext was not correctly initialized.')},
    onLogout: () => {console.log('LoginContext was not correctly initialized.')},
    isLoggedIn: () => {console.log('LoginContext was not correctly initialized.')},
    getProfile: () => {console.log('LoginContext was not correctly initialized.')},
});

class LoginProvider extends React.Component {
	static displayName = 'LoginProvider';
static contextType = LoginContext;

    constructor(props) {
        super(props);
        this.onShowLogin = this.onShowLogin.bind(this);
        this.onHideLogin = this.onHideLogin.bind(this);
        this.state = {
            showLogin: false,
            redirectOnSuccess: false,
            onShowLogin: this.onShowLogin,
            onHideLogin: this.onHideLogin,
            onLogout: this.onLogout,
            isLoggedIn: this.isLoggedIn,
            getProfile: this.getProfile,
            parentOnHide: false,
        };
    }

    onLogout = (redirect) => {
        this.props.dispatch(authLogout());
        this.props.dispatch(setProfile(null))
        if(redirect) {
            this.props.localization.redirectTo(redirect);
        }
    }

    onShowLogin(options = {}) {
        this.setState({
            showLogin: true,
            redirectOnSuccess: options.redirectOnSuccess || false,
            parentOnHide: options.onHide || false,
        });
    }

    isLoggedIn = () => {
        return this.props.auth.authentified && this.props.account.profile;
    };

    getProfile = () => {
        return this.props.account.profile;
    };

    onHideLogin() {
        if (this.state.parentOnHide) {
            this.state.parentOnHide();
        }
        this.setState({showLogin: false, parentOnHide: false});
    }

    render() {
        return (
            <LoginContext.Provider value={this.state}>
                <PopupLogin onHide={this.onHideLogin} show={this.state.showLogin} redirectOnSuccess={this.state.redirectOnSuccess}/>
                {this.props.children}
            </LoginContext.Provider>
        );
    }
}
const mapStateToProps = (state) => ({
    account: makeSelectAccount(state),
    auth: makeSelectAuth(state),
    //locale: makeSelectLocale(state)
});

export default connect(mapStateToProps)(withLocalization(LoginProvider));

export function withLogin(WrappedComponent) {
    // ... et renvoie un autre composant...
    return (props) => (
        <LoginContext.Consumer>
            {(context) => {
                return (
                    <WrappedComponent login={context}  {...props} />
                )
            }}
        </LoginContext.Consumer>
    )
}
