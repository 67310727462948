/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React from 'react';
import PropTypes from 'prop-types';
import {IntlProvider} from 'react-intl';
import {withLocalization} from "../LocalizationProvider";
import axios from "axios"
import environment from "../../environment";
import SplashScreen from "../../components/SplashScreen";

class LanguageProvider extends React.Component {
	static displayName = 'LanguageProvider';
    constructor(props) {
        super(props);
        this.messages = {};
        //this.locale = this.props.localization.country.locale;
        this.state = {
            locale: this.props.localization.country.locale,
            messages: {}
        }

    }

    async loadIntl(locale) {
        const l = locale.split('-')[0];
        try {
            if (!Intl.PluralRules) {
                await import('@formatjs/intl-pluralrules/dist/locale-data/' + l); // Add locale data
            }
            if (!Intl.RelativeTimeFormat) {
                await import('@formatjs/intl-relativetimeformat/dist/locale-data/' + l); // Add locale data
            }
        } catch (e) {
            console.error(e);
        }

    }

    async getMessages(locale) {
        await this.loadIntl(locale);
        try {
            let translationData = {};
            if (environment.environment === 'local') {
                translationData.data = require ("../../translations/fr-FR.json");
            } else {
                translationData = await axios.get(environment.cdn + `/toxseek/translation/${locale}.json?v=` + Date.now().toString());
            }
            return translationData.data;
        } catch (e) {
            console.info(e);
        }
        return {};
    }

    //async componentWillMount() {
    //    this.setStatemessages = await this.getMessages(this.locale);
    //}

    async componentDidMount() {
        this.messages = await this.getMessages(this.props.localization.country.locale);
        //this.locale = locale;
        this.setState({
            locale: this.props.localization.country.locale,
        });
    }


    async componentDidUpdate(prevProps, prevState, snapshot) {
        console.log('update', prevState, snapshot);
        if (!this.messages || prevProps.localization.country.locale !== this.props.localization.country.locale) {
            //const {locale} = this.state;

            this.messages = await this.getMessages(this.props.localization.country.locale)
            //this.setState({});
        }
    }

    static getDerivedStateFromProps(props, state) {
        if( props.localization.country.locale !== state.locale) {
            return {
                locale: props.localization.country.locale,
            };
        }
        return null;
    }


    render() {

        const {locale} = this.state;

        return  (
            <IntlProvider

                locale={locale}
                key={locale}
                messages={this.messages}
            >
                {this.props.children}
            </IntlProvider>
        );
    }
}

LanguageProvider.propTypes = {
  children: PropTypes.element,
  localization: PropTypes.object.isRequired,
};

export default  withLocalization(LanguageProvider);
