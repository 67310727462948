import React, {Suspense} from "react";
import SplashScreen from "./SplashScreen";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import LocalizationProvider from "../Providers/LocalizationProvider";
import LanguageProvider from "../Providers/LanguageProvider";
import RecaptchaProvider from "../Providers/RecaptchaProvider";
import ApiProvider from "../Providers/ApiProvider";
import {CookiesProvider} from "react-cookie";
import App from "./App";
import ApiLogErrorBoundary from "./ApiLogErrorBoundary";
import {PersistGate} from "redux-persist/integration/react";
import configureStore from "../store";


const storeConfig = configureStore();
export const store = storeConfig.store;

if (window.location.search === '?clear') {
    storeConfig.persistor.purge();
}


class Bootstrap extends React.Component
{
    render() {
        return (
            <ApiLogErrorBoundary>
                <Suspense fallback={<SplashScreen/>}>
                    <Provider store={store}>
                        <PersistGate loading={null} persistor={storeConfig.persistor}>
                            <BrowserRouter>
                                <ScrollToTop>
                                    <LocalizationProvider>
                                        <LanguageProvider>
                                            <RecaptchaProvider reCaptchaKey={"6LeSXbIUAAAAACv1XkkikL6U64UTvQ-SKoY60-gm"}>
                                                <ApiProvider>
                                                    <CookiesProvider>
                                                        <App/>
                                                    </CookiesProvider>
                                                </ApiProvider>
                                            </RecaptchaProvider>
                                        </LanguageProvider>
                                    </LocalizationProvider>
                                </ScrollToTop>
                            </BrowserRouter>
                        </PersistGate>
                    </Provider>
                </Suspense>
            </ApiLogErrorBoundary>
        )
    }
}

export default Bootstrap;
