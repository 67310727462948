import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {injectIntl} from "react-intl";
import React from "react"
import InputGroup from "react-bootstrap/InputGroup";
import {faAt, faLock} from "@fortawesome/free-solid-svg-icons";
import StateSequence from "./LoginSequence";
import NavLink from "react-bootstrap/NavLink";
import Loading from "../../Loading";
import IntlText from "../../Intl/Text";
import SplashScreen from "../../SplashScreen";

const formStyle = {
    padding: '10px',
    textAlign: 'center',
};


class LoginForm extends React.Component {
	static displayName = 'LoginForm';
constructor(props) {
        super(props);
        this.inputRefPassword = React.createRef();
        this.inputRefEmail = React.createRef();
        this.state = {
            // "DataSource" is some global data source
            validated: false,
        };
    };

    handleSubmitRecover = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            this.setState({validated: true});
            event.stopPropagation();
            return;
        }
        this.setState({validated: false});

        this.props.onRecover(this.inputRefEmail.value)
    }

    handleSubmitLogin = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            this.setState({validated: true});
            event.stopPropagation();
            return;
        }
        this.setState({validated: false});

        this.props.onLogin(this.inputRefEmail.value, this.inputRefPassword.value)
    };


    emailField = (disabled = false) => (
        <Form.Row>
            <Form.Group as={Col} controlId="validationCustomEmail">
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrependEmail"><FontAwesomeIcon icon={faAt}/></InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                        disabled={disabled}
                        ref={e => this.inputRefEmail = e}
                        type="email"
                        placeholder={this.props.intl.formatMessage({id: `account.login.form.email`})}
                        aria-describedby="inputGroupPrependEmail"
                        required
                        autoComplete="autocomplete"
                    />
                    <Form.Control.Feedback type="invalid">
                        {this.props.intl.formatMessage({id: `account.login.form.email.error`})}
                    </Form.Control.Feedback>
                </InputGroup>
            </Form.Group>
        </Form.Row>
    );

    passwordField = (
        <Form.Row>
            <Form.Group as={Col} controlId="validationCustomPassword">
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroupPrependPassword"><FontAwesomeIcon
                            icon={faLock}/></InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                        ref={e => this.inputRefPassword = e}
                        type="password"
                        placeholder={this.props.intl.formatMessage({id: `account.login.form.password`})}
                        aria-describedby="inputGroupPrependPassword"
                        required
                        autoComplete="autocomplete"
                    />
                    <Form.Control.Feedback type="invalid">
                        {this.props.intl.formatMessage({id: `account.login.form.password.error`})}
                    </Form.Control.Feedback>
                </InputGroup>
            </Form.Group>
        </Form.Row>
    );

    validateCgv = () => (
        <Form.Row>
            <Form.Group as={Col} controlId="validationAccept">
                <InputGroup>
                    <Form.Check
                        type="checkbox"
                        label={this.props.intl.formatMessage({id: `account.login.form.accept`})}
                        required
                    />
                    <Form.Check.Feedback type="invalid">
                        <IntlText messageId={`account.login.form.password.accept.error`}/>
                    </Form.Check.Feedback>
                </InputGroup>
            </Form.Group>
        </Form.Row>
    );


    submit = (disabled = false) => (
        <Form.Row>
            <Form.Group as={Col} controlId="validationSubmit">
                <button disabled={disabled} className={'ts-button-thn-login'}
                        type="submit"><IntlText messageId={`account.login.form.submit.${this.props.action}`}/></button>
            </Form.Group>
        </Form.Row>
    );

    getVariant(progress) {
        return this.state.progress < 30 ? "danger" : (this.state.progress < 60 ? "primary" : (this.state.progress < 90 ? "warning" : "success"));
    }

    getCloseButton() {
        switch (this.props.action) {
            case 'recovered':
            case 'connected':
                return (
                    <button onClick={() => this.props.onHide()} className={'mt-3 ts-button-thn-login-ok'} block>
                        <IntlText messageId={'modal.ok'}/>
                    </button>
                );
            case 'info':
                return (
                    <button disabled className={'mt-3 ts-button-thn-login-connecting'}>
                        <IntlText messageId={'modal.login.connecting'}/>
                    </button>
                );
            default:
            case 'loginError':
                return (
                    <div>
                        <p className={'text-center text-danger'}><IntlText messageId={this.props.lastError}/></p>
                        <button onClick={() => this.props.onLogin()} className={'mt-3 ts-button-thn-login-back'} block>
                            <IntlText messageId={'modal.login.back'}/>
                        </button>
                    </div>
                );
        }
    }


    loginForm = () => {

        switch (this.props.action) {
            case 'login':
                return (
                    <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmitLogin}
                          style={formStyle}>
                        {this.emailField()}
                        {this.passwordField}
                        {this.props.lastError ? (<span
                            className={'text-danger'}><IntlText messageId={this.props.lastError}/></span>) : null}
                        {this.submit()}
                        <NavLink onClick={() => this.props.onRecover()}>
                            <IntlText messageId={`account.login.actionType.${this.props.action}.link`}/>
                        </NavLink>
                    </Form>
                );
            case 'recovering':
                return (
                    <div className={"text-center"}>
                        <p className={'text-primary'}><IntlText messageId={"account.login.recovering.waiting"}/></p>
                        <SplashScreen/>
                    </div>
                );
            case 'recovered':
                return (
                    <div className={"text-center"}>
                        <p className={'text-primary'}><IntlText messageId={"account.login.recovering.success"}/></p>
                        {this.getCloseButton()}
                    </div>
                );
            case 'recoverError':
            case 'recover':
                return (
                    <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmitRecover}
                          style={formStyle}>
                        {this.emailField(this.props.action === 'recovering')}
                        {this.props.lastError ? (<span
                            className={'text-danger'}><IntlText messageId={this.props.lastError}/></span>) : null}
                        {this.submit()}
                        <NavLink onClick={() => this.props.onLogin()}>
                            <IntlText messageId={`account.login.actionType.recover.link`}/>
                        </NavLink>
                    </Form>
                )
            case 'info':
            case 'connected':
            case 'loginError':
            default:
                return (
                    <div className={"text-center"}>
                        {<StateSequence sequences={this.props.loginSequences}/>}
                        {this.getCloseButton()}
                    </div>

                );
        }
    };


    render() {
        return this.loginForm();
    }
}


export default injectIntl(LoginForm);
