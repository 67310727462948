/*
 *
 * LanguageProvider reducer
 *
 */

import produce from 'immer';

import {SET_ORDERS, SET_PROFILE, SET_RESULTS} from './constants';


export const initialState = {
    profile: null,
    orders: null,
    results: null,
};

/* eslint-disable default-case, no-param-reassign */
const accountReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case SET_PROFILE:
                draft.profile = action.profile;
                break;
            case SET_ORDERS:
                draft.orders = action.orders;
                break;
            case SET_RESULTS:
                draft.results = action.results;
                break;
        }
    });

export default accountReducer;
