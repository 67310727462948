/*
 *
 * AuthProvider constants
 *
 */

export const AUTH_LOGIN = 'auth/TOKEN';

export const AUTH_LOGOUT = 'auth/LOGOUT';

