import {createSelector} from 'reselect';
import {initialState} from './reducer';

/**
 * Direct selector to the language domain
 */

const selectAccount = state => state.account || initialState;

/**
 * Select the language locale
 */

const makeSelectAccount = createSelector(
        selectAccount,
        account => account
    );


export { selectAccount, makeSelectAccount};
